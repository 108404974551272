//variabili di resize di bootstrap
$cw-screen-base:	$screen-lg-desktop; 	//1200
$cw-screen-xl:	 	1399px;					 			//1399
$cw-screen-lg:	 	$screen-md-max; 			//1199
$cw-screen-md:		$screen-sm-max; 			//991
$cw-screen-sm: 		$screen-xs-max; 			//767
$cw-screen-xs: 		640px; 								//640
$cw-screen-xxs: 	$screen-xs; 					//480

//padding measures
$padding-base-vertical: 							1.7rem ;
$padding-base-horizontal: 						1.7rem ;

$padding-xxs-vertical: 								$padding-base-vertical/3;
$padding-xxs-horizontal: 							$padding-base-horizontal/3;

$padding-xs-vertical: 								$padding-base-vertical/2;
$padding-xs-horizontal: 							$padding-base-horizontal/2;

$padding-small-vertical: 							$padding-base-vertical/1.5;
$padding-small-horizontal: 						$padding-base-horizontal/1.5;

$padding-large-vertical: 							$padding-base-vertical*1.5;
$padding-large-horizontal: 					  $padding-base-horizontal*1.5;

$padding-xl-vertical: 								$padding-base-vertical*3.5;
$padding-xl-horizontal: 							$padding-base-horizontal*3.5;

$padding-xxl-vertical: 								$padding-base-vertical*5;
$padding-xxl-horizontal: 							$padding-base-horizontal*5;

$navbar-default-height: 							85px;
$navbar-default-height-xxs: 					78px;


$screen-size: $cw-screen-xl, $cw-screen-lg, $cw-screen-md, $cw-screen-sm, $cw-screen-xs, $cw-screen-xxs;
$default-size: xl, lg, md, sm, xs, xxs;

$screen-size-inverted: 0, $cw-screen-xxs, $cw-screen-xs, $cw-screen-sm, $cw-screen-md, $cw-screen-lg, $cw-screen-xl;
$default-size-inverted: xxs, xs, sm, md, lg, xl, xxl;

@mixin background-opaco($colore:#ffffff, $opacita: .9) {
	background-color:	$colore;
	background-color:       rgba($colore,$opacita);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($colore,$opacita))}',endColorstr='#{ie-hex-str(rgba($colore,$opacita))}');
	 -ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($colore,$opacita))}',endColorstr='#{ie-hex-str(rgba($colore,$opacita))}')";
}

@mixin background-pieno($colore:#ffffff, $opacita: 1) {
	background-color:	$colore;
	background-color:       rgba($colore,$opacita);
	filter:progid:DXImageTransform.Microsoft.gradient(enabled=false);
  	-ms-filter:"progid:DXImageTransform.Microsoft.gradient(enabled=false)";
}

@mixin owl-arrow($owl-arrow-width, $owl-arrow-left, $owl-arrow-right) {
	width: $owl-arrow-width;
	height: $owl-arrow-width;
	left: $owl-arrow-left;
	right: $owl-arrow-right;
	top: -webkit-calc(50% - #{$owl-arrow-width/2});
		 top: -moz-calc(50% - #{$owl-arrow-width/2});
					top: calc(50% - #{$owl-arrow-width/2});

	position: absolute;
	cursor: pointer;
}

@mixin cw-border-radius($border-radius-top, $border-radius-right, $border-radius-bottom, $border-radius-left) {
	-webkit-border-radius: $border-radius-top $border-radius-right $border-radius-bottom $border-radius-left;
	 -khtml-border-radius: $border-radius-top $border-radius-right $border-radius-bottom $border-radius-left;
	   -moz-border-radius: $border-radius-top $border-radius-right $border-radius-bottom $border-radius-left;
   				border-radius: $border-radius-top $border-radius-right $border-radius-bottom $border-radius-left;
}

@mixin cw-transform3d($cw-transform-x, $cw-transform-y, $cw-transform-z) {
	-webkit-transform: scale3D($cw-transform-x, $cw-transform-y, $cw-transform-z);
			-ms-transform: scale3D($cw-transform-x, $cw-transform-y, $cw-transform-z);
					transform: scale3D($cw-transform-x, $cw-transform-y, $cw-transform-z);
}

@mixin cw-transform-rotateY($cw-transform-x) {
	-webkit-transform: rotateY($cw-transform-x);
			-ms-transform: rotateY($cw-transform-x);
					transform: rotateY($cw-transform-x);
}

@mixin cw-transform-translateX($cw-transform-x) {
	-webkit-transform: translateX($cw-transform-x);
			-ms-transform: translateX($cw-transform-x);
					transform: translateX($cw-transform-x);
}

@mixin cw-transform-translateY($cw-transform-y) {
	-webkit-transform: translateY($cw-transform-y);
			-ms-transform: translateY($cw-transform-y);
					transform: translateY($cw-transform-y);
}

@mixin cw-transform-translate($cw-transform-x, $cw-transform-y) {
	-webkit-transform: translate($cw-transform-x, $cw-transform-y);
			-ms-transform: translate($cw-transform-x, $cw-transform-y);
					transform: translate($cw-transform-x, $cw-transform-y);
}

//passa l'operatore tra le virgolette devi scrivere - così "-"
@mixin cw-width-calc($cw-value-1, $cw-sign, $cw-value-2) {
	width: -webkit-calc(#{$cw-value-1} #{$cw-sign} #{$cw-value-2});
		 width: -moz-calc(#{$cw-value-1} #{$cw-sign} #{$cw-value-2});
					width: calc(#{$cw-value-1} #{$cw-sign} #{$cw-value-2});
}
//passa l'operatore tra le virgolette devi scrivere - così "-"
@mixin cw-height-calc($cw-value-1, $cw-sign, $cw-value-2) {
	height: -webkit-calc(#{$cw-value-1} #{$cw-sign} #{$cw-value-2});
		 height: -moz-calc(#{$cw-value-1} #{$cw-sign} #{$cw-value-2});
					height: calc(#{$cw-value-1} #{$cw-sign} #{$cw-value-2});
}

.cw-relative{
	position: relative;
}

.cw-absolute{
	position: absolute;
}

.cw-left{
	float:left;
}

.cw-right{
	float:right;
}

::selection {
  background: lighten($brand-primary, 20%); /* WebKit/Blink Browsers */
	color: #fff  !important;
}
::-moz-selection {
  background: lighten($brand-primary, 20%); /* Gecko Browsers */
	color: #fff  !important;
}

.navbar-absolute-top {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

.cw-bg-default{
	@include background-opaco(#ffffff,.85);
}

.cw-bg-white{
	background-color: #fff;
}

// *=== CENTRA IMMAGINE ===* //

.post {
 &-image-container {

  &.compat-object-fit {
   background-size: cover;
   background-position: center center;

   .post-featured-image { // hide image if object fit is not supported - opacity to 0 for the link area
    opacity: 0;
   }
  }
 }

 &-featured-image {
  object-fit: cover;
 }
}

.cw-vertical-align-middle {
	vertical-align: middle;
}

.post-featured-image {
    width:100%;
    height:100%;
    object-fit:cover;
}

.fa-brand-color{
	color: $brand-primary;
}


$percentage-dimension: 		auto, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;
$display-type:						inline, inline-block, block, table, table-cell, table-row, table-column, flex, none;
$float-type:							none, left, right;
$list-text-align: 				left, center, right;
$new-size-viewport:				xxs, xl;
$dimensione: 							xxs, xs, small, base, large, xl, xxl;
$margin-taglia-h: 				$margin-xxs-horizontal, $margin-xs-horizontal, $margin-small-horizontal, $margin-base-horizontal, $margin-large-horizontal, $margin-xl-horizontal, $margin-xxl-horizontal;
$margin-taglia-v: 				$margin-xxs-vertical, $margin-xs-vertical, $margin-small-vertical, $margin-base-vertical, $margin-large-vertical, $margin-xl-vertical, $margin-xxl-vertical;
$padding-taglia-h: 				$padding-xxs-horizontal, $padding-xs-horizontal, $padding-small-horizontal, $padding-base-horizontal, $padding-large-horizontal, $padding-xl-horizontal, $padding-xxl-horizontal;
$padding-taglia-v: 				$padding-xxs-vertical, $padding-xs-vertical, $padding-small-vertical, $padding-base-vertical, $padding-large-vertical, $padding-xl-vertical, $padding-xxl-vertical;
$space-type:							margin, padding;
$space-position:					top, right, bottom, left;


$name: name;
$title: title;
$subtitle: subtitle;
$description: description;
$link: link;
$btn: btn;

$main: main;
$section: section;
$header: header;
$footer: footer;
$p: p;
$aside: aside;

@mixin component-level() {  // passaggio 1

	&-#{$name} {
		font-weight: 400;
		font-family: $font-family-sans-serif;
		text-transform:none;
		padding: $padding-large-vertical;
		@include component-element(4.4rem,3.2rem,$name);

		@media screen and (max-width: $cw-screen-sm) {
			padding: $padding-base-vertical;
		}

	}

	&-#{$title}{
		font-weight: 600;
		@include component-element(1.6rem,1.7rem,$title);
	}

	&-#{$subtitle}{
		@include component-element(1.3rem,1.7rem,$subtitle);
	}

	&-#{$description}{
		@include component-element(1rem,1.7rem,$description);
	}

	&-#{$link}{
		@include component-element(1rem,1.7rem,$link);
	}

	&-#{$btn}{
		@include component-element(2.4rem,1.7rem,$btn);
		font-weight: bold;
	}

}

@mixin component-first-tag() {  // passaggio 2
	&-#{$section}{
		@include component-second-tag();
		@include component-element(1rem,1.7rem,$section);
	}

	&-#{$footer}{
		@include component-second-tag();
	}

}

@mixin component-element($cw-font-size,$cw-line-height,$nome-padre) { // passaggio 3

	&-hero{
			font-size: $cw-font-size*1.15;
			line-height: $cw-line-height*1.1;
			@include component-taglia($cw-font-size*1.15, $cw-line-height*1.15);
	}

	&-content{
			font-size: $cw-font-size*1;
			line-height: $cw-line-height*1.05;

			// @if $nome-padre == $name{
			// 	background-color: $brand-primary;
			// 	color: $brand-tertiary;
			// }

			// @if $nome-padre == $title{
			// 	body.news & {
			// 		@media screen and (min-width: $cw-screen-xs*1+1) {
			// 			font-size: $cw-font-size*.78;
			// 			line-height: $cw-line-height*.78;
			// 		}
			// 	}
			// }

			@if $nome-padre == $subtitle{
				body.news & {
					@media screen and (min-width: $cw-screen-xs*1+1) {
						font-size: $cw-font-size*.75;
						line-height: $cw-line-height*.75;
					}
				}
			}

			@include component-taglia($cw-font-size*1,$cw-line-height*1);
	}

	&-subcontent{

			@if $nome-padre == $section{
				padding-top: 					$padding-large-vertical;
				padding-bottom: 			$padding-large-vertical;
			} @else {

				font-size: $cw-font-size*0.8;
				line-height: $cw-line-height*0.8;

				@include component-taglia($cw-font-size*1,$cw-line-height*1);

				@media screen and (max-width: $cw-screen-xs) {
					font-size: $cw-font-size*1;
					line-height: $cw-line-height*1;
				}
			}
	}

	&-sidebar{
			font-size: $cw-font-size*0.9;
			line-height: $cw-line-height*0.9;

	 		@include component-taglia($cw-font-size*1,$cw-line-height*1);

			@if $nome-padre == $name{
				background-color: $brand-tertiary;
				color: $brand-secondary;
			}

			@media screen and (max-width: $cw-screen-xs) {
				font-size: $cw-font-size*1;
				line-height: $cw-line-height*1;
			}
	}
}

@mixin component-second-tag() {  // passaggio 2

	& > :first-child{
		margin-top: 0;
	}

	& > :last-child{
		margin-bottom: 0;
	}

	& h1, & h3, & time  {
		margin-bottom: 5px;
	}

	& p{
		margin-bottom: 15px;
	}

	& time  {
		margin-bottom: 10px;
	}
}

//si potrebbe includere in component level una serie di include chi chiamano mixin specifici per ogni sezione passando dei valori
@mixin component-taglia($cw-font-size, $cw-line-height) {
	&.cw-text-xxs {
		font-size: $cw-font-size*0.5;
		line-height: $cw-line-height*0.5;

	} // xs

	&.cw-text-xs {
		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: $cw-font-size*0.75;
			line-height: $cw-line-height*0.75;
		}

	} // xs

	&.cw-text-small {
			//reset font-size
			@media screen and (min-width: $cw-screen-xs*1+1) {
				font-size: $cw-font-size*0.85;
				line-height: $cw-line-height*0.85;
			}
	} // small

	&.cw-text-base {
		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: $cw-font-size*1;
			line-height: $cw-line-height*1;
		}
	} // base

	&.cw-text-large {
		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: $cw-font-size*1.25;
			line-height: $cw-line-height*1.25;
		}
	} // large

	&.cw-text-xl {

		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: $cw-font-size*1.5;
			line-height: $cw-line-height*1.5;
		}

	} // xl

	&.cw-text-xxl {
		font-size: $cw-font-size*2;
		line-height: $cw-line-height*2;

	} // xxl
}

.col-xxs-6{
	@media screen and (max-width: $cw-screen-xs) {
		width: 50%;
	}
}

.col-xxs-12{
	@media screen and (max-width: $cw-screen-xs) {
		width: 100%;
	}
}

.col-xxs-offset-0{
	@media screen and (max-width: $cw-screen-xs) {
		margin-left: 15px;
		margin-right: 15px;
	}
}

.cw{
	@include component-level(); // passaggio 1
	@include component-first-tag(); // //per i container usa un padding-top giusto, tipo 40px e il padding-top invece è dato dalla differenza tra 40px e il margin-bottom degli elementi contenuti

	@for $i from 1 through length($dimensione) {

			&-margin-h-#{nth($dimensione, $i)}{
				margin-left: 				#{nth($margin-taglia-h, $i)};
				margin-right:  			#{nth($margin-taglia-h, $i)};
			}

			&-margin-v-#{nth($dimensione, $i)}{
				margin-top: 				#{nth($margin-taglia-v, $i)};
				margin-bottom: 			#{nth($margin-taglia-v, $i)};
			}

			&-padding-h-#{nth($dimensione, $i)}{
				padding-left: 	 		#{nth($padding-taglia-h, $i)};
				padding-right: 			#{nth($padding-taglia-h, $i)};
			}

			&-padding-v-#{nth($dimensione, $i)}{
				padding-top: 				#{nth($padding-taglia-v, $i)};
				padding-bottom: 		#{nth($padding-taglia-v, $i)};
			}
	}

	//cw-n-display-type
	@for $i from 1 through length($screen-size-inverted) {
		&-display-#{nth($default-size-inverted, $i)} { //XS XXS ETC

			@if ($i == 1){
				@for $i from 1 through length($display-type){
					&-#{nth($display-type, $i)} {
						display: #{nth($display-type, $i)} !important;
					}
				}
			}
			@else{
				@media screen and (min-width: (nth($screen-size-inverted, $i))*1+1) {
					@for $i from 1 through length($display-type){
						&-#{nth($display-type, $i)} {
							display: #{nth($display-type, $i)} !important;
						}
					}
				}
			}
		}
	}




	//cw-float-n
	@for $i from 1 through length($screen-size-inverted) {
		&-float-#{nth($default-size-inverted, $i)} { //XS XXS ETC

			@if ($i == 1){
				@for $i from 1 through length($float-type){
					&-#{nth($float-type, $i)} {
						float: #{nth($float-type, $i)};
					}
				}
			}
			@else{
				@media screen and (min-width: (nth($screen-size-inverted, $i))*1+1) {
					@for $i from 1 through length($float-type){
						&-#{nth($float-type, $i)} {
							float: #{nth($float-type, $i)};
						}
					}
				}
			}
		}
	}

	@mixin cw-percentage($valore) {

		//cw-n-percentage-dimension
		@for $i from 1 through length($screen-size-inverted) {
			&-#{$valore}-per-#{nth($default-size-inverted, $i)} { //XS XXS ETC

				@if ($i == 1){
					@for $i from 1 through length($percentage-dimension){

							&-#{nth($percentage-dimension, $i)} {

								@if ($i == 1){ // se il valore è auto
									#{$valore}: #{nth($percentage-dimension, $i)};

								}@else{
									#{$valore}: (nth($percentage-dimension, $i))*1%;
								}
						}
					}
				}
				@else{
					@media screen and (min-width: (nth($screen-size-inverted, $i))*1+1) {
						@for $i from 1 through length($percentage-dimension){

								&-#{nth($percentage-dimension, $i)} {

									@if ($i == 1){ // se il valore è auto
										#{$valore}: #{nth($percentage-dimension, $i)};

									}@else{
										#{$valore}: (nth($percentage-dimension, $i))*1%;
									}
							}
						}
					}
				}
			}
		}
	}

	@include cw-percentage(width);
	@include cw-percentage(height);

	@for $i from 1 through length($space-position) {
		&-margin-#{nth($space-position, $i)}-0{
			margin-#{nth($space-position, $i)}: 0;
		}

		&-padding-#{nth($space-position, $i)}-0{
			padding-#{nth($space-position, $i)}: 0;
		}
	}

}



.cw-text {
	//cw-n-text-type
	@for $i from 1 through length($screen-size-inverted) {
		&-#{nth($default-size-inverted, $i)} { //XS XXS ETC

			@if ($i == 1){
				@for $i from 1 through length($list-text-align){
					&-#{nth($list-text-align, $i)} {
						text-align: #{nth($list-text-align, $i)} !important;
					}
				}
			}
			@else{
				@media screen and (min-width: (nth($screen-size-inverted, $i))*1+1) {
					@for $i from 1 through length($list-text-align){
						&-#{nth($list-text-align, $i)} {
							text-align: #{nth($list-text-align, $i)} !important;
						}
					}
				}
			}
		}
	}

}


@media screen and (max-width: $cw-screen-sm) {

.reset-effects-sm {
	-webkit-transform: none !important;
		 -moz-transform: none !important;
			-ms-transform: none !important;
			 -o-transform: none !important;
					transform: none !important;

	-webkit-transition: none !important;
		 -moz-transition: none !important;
			-ms-transition: none !important;
			 -o-transition: none !important;
					transition: none !important;
		//opacity: 1 !important;
}

}


// *=== Gallery ===* //

@mixin cw-gallery($quante,$margine) {  // passaggio 1

		&-#{$quante} {
			margin-top: $margine*1%;

			& > li {
				margin: $margine*1% $margine*1% 0 0;
				//sottraggo al 100% il totale della somma dei margini e in seguito lo divido per quante thumb voglio per riga
				width: ( (100% - ( ($margine*($quante)-1))) / $quante);
				padding-bottom: ( (100% - ( ($margine*($quante)-1))) / $quante);


				// $quantepiuuno: $quante +1;
				// &:nth-child(#{$quantepiuuno}n){
				// 	margin-right: $margine*1%;
				// }

				&:nth-child(n){
					margin-right: $margine*1%;
				}

				&:nth-child(#{$quante}n){
					margin-right: 0;
				}

			}
		}
}

.cw-gallery{

	margin-left: 0;
	margin-bottom: $margin-xl-vertical;

	& > li  {
		height: 0;
		overflow: hidden;
		display: block;
		float: left;
		position: relative;

		& > a{

			& > img{
				position: absolute;
				top: -9999px;
				bottom: -9999px;
				left: -9999px;
				right: -9999px;
				margin: auto;
				height: 100%;
			}
		}
	}

	@for $i from 1 through length($screen-size-inverted) {
		&-#{nth($default-size-inverted, $i)} {

			@if ($i == 1){
				@for $i from 1 through 12{
					@include cw-gallery($i,1);
				}
			}
			@else{
				@media screen and (min-width: (nth($screen-size-inverted, $i))*1+1) {
					@for $i from 1 through 12{
						@include cw-gallery($i,1);
					}
				}
			}
		}
	}
}

.cw-img-left, .cw-img-right {
	float: left;
	padding: 0;
	margin: $margin-base-vertical $margin-base-horizontal $margin-base-vertical 0;

	& > img{
		width: 100%;
		height: auto;
	}
}

.cw-img-right{
	float: right;
	margin: $margin-base-vertical 0 $margin-base-vertical $margin-base-horizontal;
}

.cw-important {
	color: $brand-primary;
}

.cw-bg-size-cover {
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	background-attachment: fixed;
}


// *=== STICKY FOOTER ===* //

html,body {
  height:100%;
  width: 100%;
	// overflow-x: hidden;
}

body {
  // display: table;
	// table-layout: fixed;
}

.container {
    height: auto;
}

footer {
    // display: table-row;
    // height: 1px;
}

// *=== PREOADER ===* //

.overflow-hidden{
	overflow: hidden;
}

#preloader  {
	position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $brand-primary;
  z-index: 9999;
  height: 100%;
	width: 100%;
 }

#status  {
  width: 60px;
  height: 60px;
  position: fixed;
  left: 50%;
  top: 50%;
  // background-image: url(ajax-loader.gif);
  // background-repeat: no-repeat;
  // background-position: center;
  margin: -30px 0 0 -30px;
 }




 // *=== BACKGROUND GRADIENT ===* //

 /// Convert angle
 /// @param {Number} $value - Value to convert
 /// @param {String} $unit - Unit to convert to
 /// @return {Number} Converted angle
 @function convert-angle($value, $unit) {
   $convertable-units: deg grad turn rad;
   $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
   @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
     @return $value
              / nth($conversion-factors, index($convertable-units, unit($value)))
              * nth($conversion-factors, index($convertable-units, $unit));
   }

   @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
 }

 /// Test if `$value` is an angle
 /// @param {*} $value - Value to test
 /// @return {Bool}
 @function is-direction($value) {
   $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
   $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

   @return $is-direction or $is-angle;
 }

 /// Convert a direction to legacy syntax
 /// @param {Keyword | Angle} $value - Value to convert
 /// @require {function} is-direction
 /// @require {function} convert-angle
 @function legacy-direction($value) {
   @if is-direction($value) == false {
     @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
   }

   $conversion-map: (
     to top          : bottom,
     to top right    : bottom left,
     to right top    : left bottom,
     to right        : left,
     to bottom right : top left,
     to right bottom : left top,
     to bottom       : top,
     to bottom left  : top right,
     to left bottom  : right top,
     to left         : right,
     to left top     : right bottom,
     to top left     : bottom right
   );

   @if map-has-key($conversion-map, $value) {
     @return map-get($conversion-map, $value);
   }

   @return 90deg - convert-angle($value, 'deg');
 }

 /// Mixin printing a linear-gradient as well as a plain color fallback and the `-webkit-` prefixed declaration
 /// @access public
 /// @param {String | List | Angle} $direction - Linear gradient direction
 /// @param {Arglist} $color-stops - List of color-stops composing the gradient
 @mixin linear-gradient($direction, $color-stops...) {
   @if is-direction($direction) == false {
     $color-stops: ($direction, $color-stops);
     $direction: 180deg;
   }

   background: nth(nth($color-stops, 1), 1);
   background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
   background: linear-gradient($direction, $color-stops);
 }

 // Tests
 .test-1 {
   @include linear-gradient(#31B7D7, #EDAC7D);
 }

 .test-2 {
   @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
 }

 .test-3 {
   @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
 }

 .cw-overflow-hidden{
 	overflow: hidden;
 }

 .cw-overflow-hidden-x{
 	overflow-x: hidden;
 }

 .cw-overflow-hidden-y{
 	overflow-y: hidden;
 }
