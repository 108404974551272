body.landing{
	background-color: #fff;
	color: #666;
	margin-top: 0;
	padding-top: 0;

	.navbar-brand{
		position: absolute;
	}

	header{
		height: 620px;

		@media screen and (max-width: $cw-screen-md) {
			height: 800px;
		}

		@media screen and (max-width: $cw-screen-xs) {
			height: auto;
		}

		& .post-image-container{
			height: 100%;

			@media screen and (max-width: $cw-screen-xs) {
				height: auto;
			}

			& .post-featured-image {
    		min-height: 250px;
			}
		}
	}

	// .list-privacy-credits-footer{
	// 	margin-bottom: 0;
	// }

	footer {
		li, a.btnScroll {
		 color: #fff;
		}
	}

	.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
    color: #ff0500;
	}


	h1.cw-title-hero {
    font-size: 3.4rem;
    line-height: 3.7rem;
		font-weight: 400;
		text-shadow: 1px 2px 3px #000;
		margin-top: -5px;

		&.cw-title-hero-landing-realizzazione-siti-web {
	    font-size: 3.2rem;
	    line-height: 3.8rem;
		}

		@media screen and (max-width: $cw-screen-xs) {
			font-size: 2.5rem;
			line-height: 2.9rem;
			text-shadow: none;

		}
  }



	h3.cw-subtitle-hero {
    font-size: 2rem;
    line-height: 2.7rem;
		font-weight: 400;
		text-shadow: 1px 2px 3px #000;
		margin-bottom: 30px;

		@media screen and (max-width: $cw-screen-xs) {
			margin-top: 10px;
			font-size: 1.9rem;
			line-height: 2.4rem;

			text-shadow: none;
		}
}

	h2.landing, h4.landing{
		color: #CB3443;
		font-weight: 400;
	}

	h2.landing {
		font-size: 3.9rem;
		margin-bottom: 2rem;
	}

	h3.cw-subtitle-section {
		font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
		font-weight: 400;
	}

	h4, .h4-link {
    font-size: 1.9rem;
    line-height: 2.2rem;
    text-transform: uppercase;
		font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;
	}

	.form-group {
    margin-bottom: 0;
	}

	.privacy-text {
		font-size: 12px;
		text-align: left;
	}

	.form-control {
		// @include background-pieno();

		background-color: #fff;
    border-color: #fff;
		color: #333;

		// @media screen and (max-width: $cw-screen-xs) {
		// 	@include background-pieno();
		// }
	}

	.form-control::-webkit-input-placeholder { color: #777; }
	.form-control:-moz-placeholder { color: #777; }
	.form-control::-moz-placeholder { color: #777; }
	.form-control:-ms-input-placeholder { color: #777; }


	.cw-form-contact-invia .btn {
		margin-top: 10px;
    padding: 4px 10px;

		@media screen and (max-width: $cw-screen-xs) {
			background-color: #fff;
			border-color: #fff;
			color: #B12D3A;
		}

	}

	.cw-form-contact span {
    font-size: 1.9rem;
	}

	#contactForm a{
		@media screen and (max-width: $cw-screen-xs) {
			color: #333;
		}
	}

	input.form-control {
    padding: 10px;
    height: 45px;
		font-size: 1.05rem;

		@media screen and (max-width: $cw-screen-md) {
			font-size: 1.3rem;
		}
	}


	.opacity-block{
		opacity: 0.6;
		z-index: 1;

		@media screen and (max-width: $cw-screen-xs) {
			display: block;
			opacity: 0.2;
		}
	}

	.cw-hero-box{
		opacity: 1;

		@media screen and (max-width: $cw-screen-xs) {
			// background-color: darken($brand-primary,5%);
			@include linear-gradient(to right, #cb3443, #f08761);			
		}
	}

	.lead {
    font-weight: 400;
	}

	.line-gradient{
		width: 100%;
	}

	#contactForm .control-group .form-group {

		&:nth-child(1){
			padding-right: 5px;

			@media screen and (max-width: $cw-screen-xs) {
				padding-right: 15px;
			}
		}

		&:nth-child(2){
			padding-left: 5px;

			@media screen and (max-width: $cw-screen-xs) {
				padding-left: 15px;
			}
		}

		& textarea{
			padding: 10px;
			height: 100px;
			padding-right: 15px;
			font-size: 1.05rem;

			@media screen and (max-width: $cw-screen-md) {
				font-size: 1.3rem;
			}
		}

		&.textarea-container {
			padding-right: 15px;
		}


	}

	@media screen and (max-width: $cw-screen-xxs) {

		.col-xxxs-12{
			width: 100%;
		}

	}

	@media screen and (max-width: $cw-screen-md) {

	// .cw-hero-box-inner {
	//     display: block;
	//     float: left;
	//     width: 100%;
	// 	}
	}

}
