/*
Questo scss va messo prima di tutti gli altri perchè usa una logica inversa rispetto al css
ovvero: nel css metti un valore per ultimo afffinchè abbia precedenza rispetto a uno scritto prima
in sass invece ci sono variabili cui viene posto alla fine il valore !default, per sovrascrviere queste
devi mettere il nuovo valore in un file sopra di loro.
	*/
$bootstrap-sass-asset-helper: false;
$icon-font-path: "../fonts/";


$cw-bg-image-repeat:   	            	#fff url("../images/bg-repeat.png") repeat ;


$font-family-serif:       						"Noto Serif", Georgia, Times, serif ;
$font-family-sans-serif:       				"Raleway", Helvetica, Arial, sans-serif ;
$font-family-base:       							$font-family-sans-serif;
// $font-family-italic:									"PT Serif", Georgia, Times, serif ;



$font-size-base:          						14px ;
$font-size-xs:         								ceil(($font-size-base * 0.5)) ;
$font-size-small:         						ceil(($font-size-base * 0.85)) ;
$font-size-large:        							ceil(($font-size-base * 1.25)) ;
$font-size-xl:        								ceil(($font-size-base * 1.5)) ;



$font-size-h1: 	           						2.6rem ;
$font-size-h2: 	           						2.15rem ;
$font-size-h3: 	           						1.6rem ;
$font-size-h4: 	           						1.25rem ;
$font-size-h5: 	           						1rem ;
$font-size-h6: 	           						0.85rem ;

$line-height-base:   	     						1.428571429 ;
$line-height-computed:    						floor(($font-size-base * $line-height-base)) ;

$font-weight-light:    								300 ;
$font-weight-regular:    							400 ;
$font-weight-semibold:    						600 ;
$font-weight-bold:    								700 ;

$border-radius-base:        15px ;
$border-radius-large:       floor(($border-radius-base * 1.25))  ;
$border-radius-small:       floor(($border-radius-base * 0.85)) ;

$headings-font-weight:   							600 ;
$headings-line-height:    						1.1 ;

$brand-primary:  											#CB3443 ;
$brand-secondary: 										#333333 ;
$brand-tertiary:		 									#FFFFFF ;

$link-color:          								$brand-primary ;
$link-hover-color:      							lighten($link-color, 10%) ;

//btn
$btn-default-color: 									$brand-tertiary ;
$btn-default-bg:     									$brand-primary ;
$btn-default-border:       						darken($brand-primary, 6.5%) ;

//padding measures
$padding-base-vertical: 							15px ;
$padding-base-horizontal: 						15px ;

//margin measures
$margin-base-vertical: 								1.25rem ;
$margin-base-horizontal: 							1.25rem ;

$margin-xxs-vertical: 								$margin-base-vertical/3;
$margin-xxs-horizontal: 							$margin-base-horizontal/3;

$margin-xs-vertical: 									$margin-base-vertical/2;
$margin-xs-horizontal: 								$margin-base-horizontal/2;

$margin-small-vertical: 							$margin-base-vertical/1.5;
$margin-small-horizontal: 						$margin-base-horizontal/1.5;

$margin-large-vertical: 							$margin-base-vertical*1.5;
$margin-large-horizontal: 					  $margin-base-horizontal*1.5;

$margin-xl-vertical: 									$margin-base-vertical*2;
$margin-xl-horizontal: 								$margin-base-horizontal*2;

$margin-xxl-vertical: 								$margin-base-vertical*3;
$margin-xxl-horizontal: 							$margin-base-horizontal*3;

$border-radius-base: 5px;


$btn-font-family:                			$font-family-sans-serif ;

$cw-transition: 											all .3s ease ;


$navbar-default-brand-color: 					$brand-secondary ;
$navbar-default-color:            	 	$brand-secondary ;

// Navbar toggle
$navbar-default-toggle-bg:       				   #666;
$navbar-default-toggle-hover-bg:           lighten($brand-secondary, 15%) ;
$navbar-default-toggle-icon-bar-bg:        #fff ;
$navbar-default-toggle-border-color:       $brand-secondary ;

// Navbar links
$navbar-default-link-color:  					$brand-secondary ;
$navbar-default-link-hover-color:   	$brand-primary ;
$navbar-default-link-hover-bg:       	transparent ;
$navbar-default-link-active-color:  	$brand-primary ;
$navbar-default-link-active-bg:      	transparent ;


$cw-padding-default: 20px ;
$cw-margin-top-default: 0 ;
$cw-margin-bottom-default: 30px ;
