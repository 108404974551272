html{
	text-rendering: optimizeLegibility;
	font-size: 14px;
	line-height: 18px;

	@media screen and (max-width: $cw-screen-xl) {
		font-size: 13.5px;
	}

	@media screen and (max-width: $cw-screen-lg) {
		font-size: 13px;
	}

	@media screen and (max-width: $cw-screen-md) {
		font-size: 12.5px;
	}

	@media screen and (max-width: $cw-screen-sm) {
		font-size: 12px;
	}

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 11.5px;
	}

	@media screen and (max-width: $cw-screen-xxs) {
		font-size: 10.5px;
	}
}

body{
	text-rendering: optimizeLegibility;

	background-color: #1D1D1D;
	color: #fff;

	@media screen and (max-width: $cw-screen-sm) {
		margin-top: 84px ;
	}

	@media screen and (max-width: $cw-screen-xxs) {
		margin-top: 78px ;
	}
}

a, a:hover, a:active, a:focus {
	text-decoration: none;
	transition: all .3s ease;
	outline: 0;
}

// *=== PRELOADER TYPE ===* //
#preloader  {
	@include linear-gradient(to right, rgba(203,52,67,1), rgba(240,135,97,1));
}

.sk-folding-cube {
  margin: 20px auto;
	width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
	width: 33%;
  height: 33%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
	background-color: #fff;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

// *=== Always burger ===* //

// .navbar-header {
//   float: none;
// }
// .navbar-toggle {
//   display: block;
// }
// .navbar-collapse.collapse {
//   display: none !important;
// }
// .navbar-nav {
//   float: none !important;
// }
// .navbar-nav>li {
//   float: none;
// }
// .navbar-collapse.collapse.in{
//   display:block !important;
// }

// *=== NAVBAR ===* //

// .navbar-nav {
// 	float: none ;
// 	transition: $cw-transition ;
// 	margin-right: 60px;
//
// 	@media screen and (max-width: $cw-screen-sm) {
// 		margin-right: auto;
// 		margin-left: auto;
// 	}
//
// 	& > li {
// 	    float: none;
// 	    display:inline-block;
//
// 			&  > a {
// 			   transition: $cw-transition;
// 			   font-size:  1.35rem;
// 			   margin: 0 $margin-large-horizontal;
// 			   padding: $padding-base-vertical 0;
//
// 				 @media screen and (max-width: $cw-screen-md) {
// 					 	 font-size: 1.3rem;
// 						 margin: 0 0.5rem;
// 				 }
//
// 				 @media screen and (max-width: $cw-screen-sm) {
// 					 padding: $padding-xs-vertical 0;
// 				}
//
// 		 }
//
// 		 &:last-child a{
// 			 margin-right: 0;
// 		 }
//
// 		 @media screen and (max-width: $cw-screen-xs) {
// 		 	 display: block;
// 		 	 text-align: left;
// 		 }
//
// 	}
// }

.navbar-default .container {
    text-align: center;
}

.container .navbar-header{
  display:inline-block;
  float:none;
	transition: $cw-transition;

	@media screen and (max-width: $cw-screen-sm) {
    display: block;
		padding: 15px;
	}
}

.navbar-toggle {
		background-color: $navbar-default-toggle-bg ;
		margin: 11px 0;

		@media screen and (max-width: $cw-screen-sm) {
			margin: 8px 0;
		}

		@media screen and (max-width: $cw-screen-xxs) {
			padding: 7px 8px;

			& .icon-bar {
    		width: 19px;
			}
	}
}

.navbar-brand{
	width: auto;
	height: auto;
	padding: 0;
	font-size: 2rem ;
	position: fixed;
	left: 15px;
	top: 15px;
	z-index: 1030;
	opacity: 1;
	transition: all .3s ease;

	@media screen and (max-width: $cw-screen-md) {
		top: 12px;
	}

	@media screen and (max-width: $cw-screen-sm) {
		position: absolute;
	}

}

img.logo-azienda{
	width: 70px;
	height: 70px;
	display: inline-block;

	footer &{
		width: 70px;
	}

	@media screen and (max-width: $cw-screen-lg) {
		width: 60px;
		height: 60px;
	}

	@media screen and (max-width: $cw-screen-xxs) {
		width: 54px;
		height: 54px;
	}
}

.nome-sito{
	margin-top:10px;
	padding-left: 0;
	color: $brand-tertiary;
	position: relative;
	top: 7px;

	& span.first-word{
		font-family: 'slim_joeregular';
		font-size: 2.8rem;
		line-height: 2rem;

		@media screen and (max-width: $cw-screen-sm) {
			font-size: 2.4rem;
			line-height: 1.8rem;
		}

		@media screen and (max-width: $cw-screen-xxs) {
			font-size: 2.15rem;
			line-height: 1.65rem;
		}
	}

	& span.second-word{
		font-family: 'big_johnregular';
		font-size: 1.6rem;

		@media screen and (max-width: $cw-screen-sm) {
			font-size: 1.4rem;
		}

		@media screen and (max-width: $cw-screen-xxs) {
			font-size: 1.25rem;
		}
	}

	footer &{
		font-size: 1.3rem;
		margin-top: 0;
		top: 0;

		@media screen and (max-width: $cw-screen-md) {
			font-size: 1.7rem;
		}
	}

	@media screen and (max-width: $cw-screen-sm) {
		display: inline-block;
		padding-left: 0;
		margin-top: 0;
	}

	@media screen and (max-width: $cw-screen-sm) {
		top: 5px;
	}

}

.info-azienda{
	font-style: italic;
	font-size: 1rem;

	@media screen and (max-width: $cw-screen-md) {
		font-size: 1.2rem;
		padding:  $margin-small-vertical 0;
		display: inline-block;
	}
}

// *=== Navbar-scroll ===* //

.navbar-scroll {
	padding:10px;

	&-fixed{
		position: fixed;














	}

	@media screen and (max-width: $cw-screen-sm) {
		padding: 0;
	}

	& .navbar-nav{
		margin-right: 50px;
	}

  & img.logo-azienda{
		width: 60px;
		height: 60px;

		@media screen and (max-width: $cw-screen-xxs) {
			width: 54px;
			height: 54px;
		}
	}

	& .nome-sito span.first-word {
	    font-size: 2.4rem;
	    line-height: 1.8rem;
	}

	& .nome-sito span.second-word {
	    font-size: 1.4rem;
	}

	&.cw-nav {
	    height: 84px;
	}

	& .icon{
		top: 28px;
		height: 28px;
		width: 28px;
	}

	& .navbar-brand {
    top: 13px;
	}

	& .navbar-collapse {
		& .navbar-nav > li {
			& > a,
			&:focus > a,
			&:active > a,
			&:hover > a,
			& > a.active {
				padding-bottom: 29px;

				@media screen and (max-width: $cw-screen-sm) {
					padding-bottom: 14px;
					border-bottom: 3px solid transparent;
				}
			}

			& > a {
				border-bottom: 3px solid transparent;
			}
		}
	}

}

.navbar-scroll .container{
	text-align:left;
}

.navbar.navbar-scroll > .container .navbar-brand {
    margin-left: 0;
}

.container .navbar-scroll .navbar-header{
   float:left;
   transition: $cw-transition;
}

.nav-open{
	height: 100%;
}

.navbar {
	border: none;
}

#cw-nav-ul-container.collapse.in{
	border-bottom: 1px solid #ccc !important;
	z-index: 1029;
}

 .navbar-scroll #cw-nav-ul-container {
	text-align: right;
 }

#cw-nav-ul-container {
	position: relative;
}

// *=== Navbar-default ===* //

.cw-nav-standard {  // prima navbar-default
	background-color: transparent;
	background: none;
 	padding: 15px 0;

	& .navbar-header {
		height: 100%;
	}

	& .navbar-right {
		margin-right: 15px;
		margin-top: 3px;
		transition: $cw-transition;

		@media screen and (max-width: $cw-screen-lg) {
			margin-top: 0px;
		}

		@media screen and (max-width: $cw-screen-md) {
			margin-top: -2px;
		}

		@media screen and (max-width: $cw-screen-sm) {
			margin-top: 0;
		}

	}

	& .navbar-toggle {
		margin-right: 7px;
		margin-top: 25px;
		background-color: transparent;
		background: none;

		&:hover, &:focus, &:active {
			background-color: transparent;
			background: none;
		}
	}


	& .dropdown {
		// menu nascosto in alto verso l'infinito
		& .dropdown-menu {
			@include cw-border-radius(0, 0, 0, 0);

			min-width: 245px;
			padding: 0;

			background-color: transparent;
			border: none;
			box-shadow: none;

			@media screen and (max-width: $cw-screen-md) {
				min-width: 225px;
			}

			@media (min-width: $cw-screen-sm*1+1) {
				background-color: #000;
				border: 1px solid #fff;
				display: block;

				top: -10000px;
				margin-top: 2px;
				visibility: hidden;
				opacity: 0;
				-webkit-transition: opacity 0.3s, transform 0.2s ease-out 0s;
					 -moz-transition: opacity 0.3s, transform 0.2s ease-out 0s;
						 -o-transition: opacity 0.3s, transform 0.2s ease-out 0s;
								transition: opacity 0.3s, transform 0.2s ease-out 0s;
				will-change: transform;
			}

			&	> li > a {
				color:#fff;
				 font-size: 1.1rem;
				 font-weight: 500;
				 padding: 7px 10px;

				 @media screen and (max-width: $cw-screen-sm) {
					 font-size: 1.2rem;
					 padding: 5px 25px;
				 }
			}



		}

		& .dropdown-sign::after {
				content: "+";
		}
		// effetto per il menu che compare
		&.open {

			& > a,
			& > a:hover,
			& > a:focus
			& > a:active{
		 	 color: #fff;
		  }

			& .dropdown-menu {

				@media (min-width: $cw-screen-sm*1+1) {

					@include cw-transform-translateX(15px);
					top: auto;
					left: 0;
					visibility: visible;
					opacity: 1;
				}

				&	> li > a {
					 color:#fff;

					 &:hover, &:focus, &:active{
						 color: $brand-primary;
					 }

				}

			}

			.dropdown-sign::after {
				 content: "-";
		 	}

		}

	}

	& .navbar-nav > li {

		&:focus > a,
		&:active > a,
		&:hover > a,
		& > a.active {
			// color: $brand-primary;
			// border-bottom: 1px solid $brand-primary;
			border-bottom: 3px solid $brand-primary;
			// color: $brand-primary;
			color: #fff;


			@media (max-width: $cw-screen-sm) {
				border-bottom: none;
			}
		}

		& > a {
	    color: #ffffff;

			padding-top: 19px;
			padding-bottom: 20px;

			font-size: 1.3rem;
			line-height: 1.85rem;
			font-weight: 400;

			margin-left: 1.2rem;
			margin-right: 1.2rem;

			border-bottom: 3px solid transparent;

			@media (max-width: $cw-screen-md) {
				font-size: 1.2rem;
		    line-height: 1.75rem;
				padding-left: 10px;
				padding-right: 10px;
    		padding-top: 23px;
		    // margin-left: 0.3rem;
		    // margin-right: 0.3rem;
			}

			@media (max-width: $cw-screen-sm) {
				font-size: 1.35rem;
				line-height: 1.75rem;
				font-weight: 400;
				margin-left: 0.5rem;
				margin-right: 0.5rem;
				padding-top: 14px;
				padding-bottom: 15px;
				border-bottom: none;
			}

			@media (max-width: $cw-screen-xs) {
				font-size: 1.5rem;
				line-height: 1.85rem;
				margin-left: 0rem;
				margin-right: 0rem;
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}

	&.navbar-scroll{
		background-color: #333;
		transition: $cw-transition;
		height: 85px;

		& .navbar-right {
			transition: $cw-transition;
			margin-top: -5px;

			@media (max-width: $cw-screen-sm) {
				margin-top: 0;
			}
		}

		@media screen and (max-width: $cw-screen-xxs) {
			height: 78px ;
		}
	}

	@media screen and (max-width: $cw-screen-sm) {
		@include background-pieno(#333333);

		position: fixed ;
		padding-top: 0 ;
		padding-bottom: 0;
		height: $navbar-default-height ;
		top: 0 ;
		margin-bottom: 0;

		& .navbar-collapse{
			@include background-pieno(#555555);
			border-color: #555555;
		}

		@media screen and (max-width: $cw-screen-xxs) {
			height: $navbar-default-height-xxs ;
		}

	}
}




// *=== ACCORDION ===* //

.panel-heading {
    padding: 0;
}

.panel-title > a{
	padding: 10px 15px;
	display: block;
	font-size: 1.1rem;
	background-color: $brand-primary;
	color: #fff;

	&.collapsed{
		background-color: lighten($brand-primary, 7%);
		color: #fff;
	}

	&:hover, &:focus{
		background-color: $brand-primary;
	}

	@media (max-width: $cw-screen-md) {
		font-size: 1.3rem;
	}
}

.panel-body {
	& ul {
		padding-left: 1.05rem;

		& li{
			@media (min-width: $cw-screen-xs*1+1) {
				font-size: 1.05rem;
			}

			margin-bottom: 5px;
			& a{
				font-weight: $font-weight-semibold;
			}
		}
	}
}

// *=== TYPOGRAPHY ===* //

h1,h2,h3,h4,h5,h6{
	margin:0;
}

h2{
	font-weight: $font-weight-semibold
}

h3{
	font-weight: $font-weight-regular;
	line-height: 1.8rem;
}

p{
	@media screen and (min-width: $cw-screen-xs*1+1) {
		font-size: 1.18rem;
		line-height: 1.6rem;
		margin-bottom: 20px;

		// font-size: 1.105rem;
		// line-height: 1.445rem;
		// margin-bottom: 10px;
	}
}

.btn, a.btn{
	background-color: $btn-default-bg;
	color: $btn-default-color;
	font-family: $btn-font-family ;
}

hr{
	border: 0;
	height: 1px;
	background: #333;
	margin-top: 15px;
	margin-bottom: 15px;
}

textarea {
    resize: none;
}

// *=== VARIOUS ===* //

.cw-color-brand{
	color: $brand-primary;
}

.cw-hero-box {
	padding: $padding-large-vertical $padding-base-vertical ;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: table;
	width: 60%;
	// CWH ADD 26 09 2020
	max-width: 60%; 
	// CWH END ADD 26 09 2020

	// CWH 27 10 2019
	// margin-left: auto;
	// margin-right: auto;
	margin-left: 20% ;	
	margin-right: 0 ;	
	// END CWH 27 10 2019		
	height: 100%;
	z-index:1000;
	color: $brand-tertiary;

	.header-servizio & {

		@media screen and (max-width: $cw-screen-xs) {
			height: auto;
			min-height: 0;
		}
	}

	.header-web-design &, .header-portfolio &, .header-portfolio-scheda & {

		@media screen and (max-width: $cw-screen-xs) {
			height: auto;
			padding-top: 35px;
			padding-bottom: 35px;
			min-height: 0;
		}
	}

	.header-portfolio-scheda & {
		width: 100%;

		// CWH ADD 26 09 2020
		max-width: 100%; 
		// CWH END ADD 26 09 2020
		
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;

		&-inner{
			vertical-align: bottom;
		}
	}


	.header-contatti & {
		position: relative;
		padding-top: 10rem;
		padding-bottom: 10rem;

		@media screen and (max-width: $cw-screen-sm) {
			padding-top: 6rem;
			padding-bottom: 6rem;
		}

		@media screen and (max-width: $cw-screen-xs) {
			padding: $padding-large-vertical $padding-base-vertical ;
			background-color: #1d1d1d;
			height: auto;
			min-height: 0;
		}
	}

	&-inner{
		display: table-cell;
		vertical-align: middle;
		float: none;

	}

	@media screen and (max-width: $cw-screen-xl) {
		width: 76%;

		// CWH ADD 26 09 2020
		max-width: 76%; 
		// CWH END ADD 26 09 2020

		// CWH 27 10 2019
		margin-left: 12% ;
		// END CWH 27 10 2019		
	}

	@media screen and (max-width: $cw-screen-xs) {
		width: 100%;

		// CWH ADD 26 09 2020
		max-width: 100%;
		// CWH END ADD 26 09 2020

		// CWH 27 10 2019
		margin-left: 0 ;
		// END CWH 27 10 2019

		position: relative;
		bottom: auto;
		background-color: #444;
		height: 350px;
	}

	body.internal &{
		position: relative;
		bottom: auto;
		text-transform: uppercase;
		padding: 0 ;
	}

}

.cw-scopri{
	border: 1px solid #fff;
	padding: 0.7rem 1rem;
	text-transform: uppercase;
	font-size: 1.8rem;
	margin-top: 2rem;
	margin-bottom: 2rem;

	display: inline-block;

	&:hover{
		background-color: $brand-tertiary;
		color: $brand-secondary;
	}

	@media screen and (max-width: $cw-screen-xs) {
		margin-bottom: 0;
	}
}


.cw-cite-quotes{
	padding: 0;
	margin-bottom: 0;
	// font-family: $font-family-italic;
	font-style: italic;
	padding-left: $padding-base-vertical;
	padding-right: $padding-base-vertical;
	border: none;
	line-height: 1.6rem;

	&:before, &:after{
		display: block;
		content: "\201C";
		font-size: 2rem;
		position: absolute;
		left: 0;
		top: 10px;
	}

	&:after{
		content: "\201D";
		left: auto;
		top: auto;
		right: 0;
		bottom: 0;
	}
}

#titolo-le-ricette-del-mese, .box-title-sidebar {
  display:table;
  width:100%;
  max-width:100%;
	padding: 0;
}

.title-divider{
	font-family: $font-family-sans-serif;
	text-transform: none;
	padding-left: 0;
	color: $brand-primary;
	font-size: 3.5rem;
	margin-top: 3rem;
	margin-bottom: 1.2rem;
	padding-bottom: 1rem;
	text-align: center;
	font-weight: 600;

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 3rem;
	}
}

.divider{
	width: 4rem;
	height: 3px;
	background-color: $brand-primary;
	display: inline-block;

	@media screen and (max-width: $cw-screen-xs) {
		width: 3rem;
		height: 2px;
	}
}

// *=== Titolo con righe orizzontali ai lati ===* //

.cw-title-with-lines {
	font-family: $font-family-sans-serif;
	display:table-row;
  line-height:1.7rem;
  white-space:nowrap;

	& span.spacer {
    display:table-cell;
		vertical-align: middle;

		&:after{
			display:inline-block;
		  width:100%;
	    content:".";
	    font-size:0;
	    color:transparent;
	    height:1px;
	    background: $brand-secondary;
	    vertical-align:middle;
	    position:relative;
	    top:-1px;
		}

		@media screen and (max-width: $cw-screen-xxs) {
			display: none;
		}
	}

	& h2{
		line-height: 1rem ;
		font-weight: $font-weight-regular;
		color: $brand-secondary;
		overflow:hidden;
    display:table-cell;
    vertical-align:middle;
    width:1px;
    table-layout:fixed;
		padding: $padding-large-vertical;

		@media screen and (max-width: $cw-screen-xs) {
			display: inline-block;
			white-space: normal;
			width: 100%;
			white-space: normal;
			line-height: 2rem;
			font-size: 3.2rem;
		}
	}

}

ul.list-default li > a{
	color: inherit;

	&:hover, &:active, &:focus{
		text-decoration: none;
	}
}

.list-group-item{
	font-size: 1.05rem;

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 1.3rem;
	}
}
// *=== PAGINATION ===* //

.pagination{
	body.ricette &{
		margin-bottom: 0;
	}

	& > li > a {
		font-size: 1.15rem;
		padding: 0.7rem 0.95rem;
	}

	& > .active > a{
		border-color: #DDD;
	}
}

.title-sidebar {
	margin-bottom: 10px;
	padding: 1.5rem 1.8rem;
 	font-size: 1.5rem;
 	line-height: 1.4rem;
 	font-family: $font-family-base;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	line-height: 1rem;
	overflow: hidden;
	display: table-cell;
	vertical-align: middle;
	width: 1px;
	table-layout: fixed;

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 1.8rem;
		// margin-top: 15px;
		margin-bottom: 15px;
		display: inline-block;
		width: 100%
	}
}

.cw-list-margin-b-base li{
	margin-bottom: 30px;
}

// *=== OWL CAROUSEL 2 ===* //

.owl-item{
		height:100%;

		&-inner {
		    height:450px;

				& a{
					color: inherit;
				}

				@media screen and (max-width: $cw-screen-xl) {
					height: 400px;
				}

				@media screen and (max-width: $cw-screen-sm) {
					height: 380px;
				}


				@media screen and (max-width: $cw-screen-xs) {
					height: auto;
				}
		}

		body.index &-inner {
		    height: 100%;

				@media screen and (max-width: $cw-screen-xl) {
					height: 100%;
				}

				@media screen and (max-width: $cw-screen-xs) {
					height: auto;
				}
		}

		@media screen and (max-width: $cw-screen-xs) {
			height: auto;

			& img  {
					height: 300px;
			}
		}

		@media screen and (max-width: $cw-screen-xs) {

			& img  {
					height: 250px;
			}
		}
}

.owl-carousel .owl-stage-outer {
    height: 100%;
}

#portfolio .owl-carousel .owl-stage-outer {
	@include cw-height-calc(100%, "-", 35px);
}

.owl-carousel .owl-item {
	height: 100%;

	@media screen and (max-width: $cw-screen-xs) {
		height: auto;
	}
}

.owl-carousel {
    width: 100%;
    height: 100%;

	@media screen and (max-width: $cw-screen-xs) {
		height: auto;
	}
/*
	.owl-loading-fa-spinner {
		top: 50%;
		left: 50%;
		@include cw-transform-translate(-50%,-50%) ;
		position: absolute;
		z-index: 9999 ;
	}

	.owl-loading-fa-spinner-hide {
		display: none ;
	}
*/




	.owl-loading-container {
	  @include background-opaco(#000000, .5);
	  position: absolute ;
	  top: 0 ; 
	  left: 0 ;
	  width: 100% ;
	  height: 100% ;
	  // z-index: 1001 ;
	  z-index: 998 ;
	}

	.owl-loading-fa-spinner {
	  top: 50%;
	  left: 50%;        
	  @include cw-transform-translate(-50%,-50%) ;
	  letter-spacing: normal ;
	  line-height: normal ;
	  font-size: 1.5rem ;

	  position: absolute;
	}

	.owl-loading-container-hide {
	  display: none ;
	}






}

.owl-carousel .owl-stage {
	height: 100%;

	@media screen and (max-width: $cw-screen-xs) {
		height: auto;		
	}
}


#portfolio:after{
	content: " ";
	display: block;
	height: 50px;
	width: 100%;

	@media screen and (max-width: $cw-screen-xs) {
		height: 0;
	}
}

header {
	height: 100%;
	min-height: 600px;

	&.header-servizio {
		height: 700px;
		min-height: 700px;

		@media screen and (max-width: $cw-screen-sm) {
			height: 500px;
			min-height: 500px;
		}

		@media screen and (max-width: $cw-screen-xs) {
			height: auto;
			min-height: 0;
		}
	}

	&.header-contatti {
		height: auto;
		min-height: 0;
	}

	@media screen and (max-width: $cw-screen-xs) {
		height: auto;
		min-height: 0;
	}

	&.header-web-design {
		height: 800px;
		min-height: 800px;

		@media screen and (max-width: $cw-screen-md) {
			height: 700px;
			min-height: 700px;
		}

		@media screen and (max-width: $cw-screen-sm) {
			height: 500px;
			min-height: 500px;
		}

		@media screen and (max-width: $cw-screen-xs) {
			height: auto;
			min-height: 0;
		}
	}

	&.header-portfolio, &.header-portfolio-scheda {
		height: 500px;
		min-height: 500px;
		z-index: 99;

		@media screen and (max-width: $cw-screen-md) {
			height: 350px;
			min-height: 350px;
		}

		@media screen and (max-width: $cw-screen-sm) {
			height: 300px;
			min-height: 300px;
		}

		@media screen and (max-width: $cw-screen-xs) {
			height: auto;
			min-height: 0;
		}
	}


}

.owl-home-container {
	@media screen and (max-width: $cw-screen-xs) {
		height: 600px;
		background-color: #444;
	}
}

img.left-owl, img.right-owl{
	@include owl-arrow(40px, 10px, auto);
	z-index:2;

	@media screen and (max-width: $cw-screen-xs) {
		display: none;
	}
}

img.right-owl{
	@include owl-arrow(40px, auto, 10px);
}

.list-default li{
	& img{
		height: 270px;

		body.news &{
			height: 200px;
		}

		@media screen and (max-width: $cw-screen-xs) {
			height: auto;
		}
	}

	@media screen and (max-width: $cw-screen-md) {
		margin-bottom: 0;
	}

	&:last-child{
		margin-bottom: 0;
	}
}

.margin-top-from-2nd li:nth-child(n+2){
	margin-top: $margin-xl-vertical;
}

.cw-article{
	background: $brand-tertiary;

	& h1{
		margin-bottom: $margin-base-vertical;

		body.page-ricette &{
			text-align: center;
			margin-bottom: 0;
		}
	}
}

img.img-author{
	height: 260px;

	@media screen and (max-width: $cw-screen-xs) {
		height: auto;
	}
}

.a2a_button_facebook_like{
	width: auto !important;
}

.glyphicon {
	font-size: .75em;
}

.cw-post-header-elements{
	font-size: 1.1rem;
	line-height: 1.8rem;
	margin-right: $margin-xs-horizontal;

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 1.4rem;
		line-height: 2.4rem;
	}

	&:last{
		margin-right: 0;
	}

	.box-search-elements & {
		display: inline-block;

		@media screen and (min-width: $cw-screen-xs*1+1) {
			display: block;
			font-size: 1.05rem;
		}
	}

}

// *=== SEARCH ===* //
.box-search-elements{
	@media screen and (max-width: $cw-screen-xs) {
		padding-top: 1rem;
		padding-bottom: 1rem;

	}
}

.cw-search-results{
	margin-bottom: 1rem;
}


@media (max-width: $cw-screen-sm) {
	.navbar-default .navbar-form {
		border-color: #E7E7E7;
		border: none;
	}
}


.form-control::-webkit-input-placeholder { color: #fff; }
.form-control:-moz-placeholder { color: #fff; }
.form-control::-moz-placeholder { color: #fff; }
.form-control:-ms-input-placeholder { color: #fff; }

// *=== LIST ===* //
ul.list-news, ul.list-ricette{
	margin-top: -30px;

	& li{
		margin-top: 30px;
		margin-bottom: 0;

		// & h3{
		// 	color: darken($brand-primary, 8%);
		// 	font-weight: $font-weight-bold;
		// }
	}
}

// *=== DROPDOWN ===* //
.cw-dropdown-button{
	background-color: lighten($brand-primary, 2%);
	border-color: $brand-primary;
	color: $brand-tertiary;
	font-family: $font-family-base !important;
	padding: 2px 5px;
	@media screen and (min-width: $cw-screen-xs*1+1) {
		font-size: 1.05rem;
	}
}

.cw-dropdown-menu {
	& > li{
		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: 1rem;
		}
	}
}

.visible-xxs{
	@media screen and (max-width: $cw-screen-xxs) {
		display: block !important;
	}
}

.cw-box-filtra-per, .cw-blockquote{
	width: 100%;
	color: #4E4E4E;
	display: inline-block;
	padding: 1rem 1rem 0;
	border-left: 5px solid $brand-primary;
	background-color: #f2f2f2;
}

.cw-blockquote{
	padding: 1.5rem;
	font-size: 3rem;
	line-height: 3rem;
	background-color: #fff;
}

.filtra-per{
	font-style: italic;
	margin-bottom: 1.2rem;
	font-size: 1.5rem;
	font-weight: $font-weight-bold;

	@media screen and (max-width: $cw-screen-xxs) {
		font-size: 1.8rem;
		text-align: center;
	}
}

.filtra-per-row{
	margin-bottom: 0.7rem;

	& span.nome-filtra-per{
		font-size: 1.1rem;
		font-weight: $font-weight-bold;
		margin-right: 10px;

		@media screen and (max-width: $cw-screen-xs) {
			font-size: 1.45rem;
		}
	}
}

// *=== Footer ===* //
footer{
	background-color: #0C0C0C;
	font-size: 1.1rem;

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 1.4rem;
		line-height: 2.2rem;
	}

	& a {
		// color: #fff;
	}

  .fa-facebook-container {
    & .social-color{
  		color: #3C5B9B;
  	}

    &:hover .social-color, &:focus .social-color, &:active .social-color{
      color: darken(#3C5B9B, 20%);
      transition: $cw-transition;
    }
  }

  .fa-twitter-container {
    & .social-color {
  		color: #28AAE1;
  	}

    &:hover .social-color, &:focus .social-color, &:active .social-color{
      color: darken(#28AAE1, 20%);
      transition: $cw-transition;
    }
  }

  .fa-googleplus-container {
    & .social-color{
  		color: #F63E28;
  	}

    &:hover .social-color, &:focus .social-color, &:active .social-color{
      color: darken(#F63E28, 20%);
      transition: $cw-transition;
    }
  }

  .fa-linkedin-container {
    & .social-color{
  		color: #0B78B7;
  	}

    &:hover .social-color, &:focus .social-color, &:active .social-color{
      color: darken(#0B78B7, 20%);
      transition: $cw-transition;
    }
  }

	.list-social-footer {
		margin-bottom: 1.475rem;
	}

}

.footer-row-2{
	background-color: $brand-primary;
	color: #fff;

	& a{
			color: #fff;

			&:hover, &:focus{
				color: #000;
			}
	}
}

.claim-azienda{
	font-style: italic;
	margin-left: 2px;
	font-size: 1.3rem;
}

footer .list-link-footer,
footer .list-privacy-credits-footer {
	padding: 0;
	text-align: center;
	text-transform: uppercase;

	& li{
		display: inline-block;
	}

	& li + li::before {
	    content: " | ";
			margin-left: 5px;
			margin-right: 5px;
	}
}

footer .list-privacy-credits-footer{
	& li{
		color: $brand-primary;
		text-transform: none;

	}
}

.hero-wrapper{
	height: 400px;

	@media screen and (max-width: $cw-screen-md) {
		height: 350px;
	}

	@media screen and (max-width: $cw-screen-sm) {
		height: 300px;
	}

	@media screen and (max-width: $cw-screen-xs) {
		height: 250px;
	}

	body.interal-without-hero &{
		height: auto;
	}

}

.cw-box-title{
	body.page-ricette & {
		background-color: $brand-primary;
		color: #fff;
	}

	& article{
		border: 1px solid rgb(212, 212, 212);
	}
}

ul.cw-list-post-header-elements{
	margin-left: 5px;

	& > li{
		padding: 0;
		margin-right: 3px;
		margin-bottom: 3px;

		& > a {
			background-color: lighten($brand-primary, 2%);
			color: #fff;
			padding: 1px 4px;
			@include cw-border-radius(4px, 4px, 4px, 4px);
			transition: all .3s ease;
			font-size: 1.05rem;

			&:hover, &:focus, &:active{
				background-color: darken($brand-primary, 5%);
			}

			@media screen and (max-width: $cw-screen-xs) {
				padding: 3px 7px;
			}
		}

		@media screen and (max-width: $cw-screen-xs) {
			margin-bottom: 10px;
		}
	}

}

span.tags {
    background-color: #FF8F21;
    color: #FFF;
    padding: 1px 4px;
		@include cw-border-radius(4px, 4px, 4px, 4px);
    transition: all 0.3s ease 0s;

		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: 1.05rem;
		}

}

ul.cw-list-more-reads > li{
	margin-bottom: $margin-base-vertical;

	& img{
		height: auto;
	}

	& .cw-title{
		font-size: 1.1rem;
		line-height: 1.35rem;

		@media screen and (max-width: $cw-screen-sm) {
			font-size: 1.5rem;
			line-height: 1.6rem;
		}
	}

	&:last-child{
		margin-bottom: 0;
	}
}

#contactForm .form-control::-webkit-input-placeholder { color: #999; }
#contactForm .form-control:-moz-placeholder { color: #999; }
#contactForm .form-control::-moz-placeholder { color: #999; }
#contactForm .form-control:-ms-input-placeholder { color: #999; }

body.contact{
	#contactForm .cw-btn{
		font-size: 1.4rem;
		padding: 0.75rem;
		font-family: $font-family-base;
	}

	& p{
		margin-bottom: 20px;
		display: inline-block;
	}
}

.cw-icon {
	font-size: 1.2rem;
}

.search article {
	border: none;
	margin-bottom: 30px;
	height: 140px;
	overflow: hidden;

	&:last-child{
		margin-bottom: 0;
	}

	@media (max-width: $cw-screen-lg) {
		height: 170px;
	}

	@media (max-width: $cw-screen-md) {
		height: 180px;
	}

	@media (max-width: $cw-screen-sm) {
		height: auto;
	}
}

body.search{
	.search-h4 {
		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: 1.2rem;
		}
	}

	.search p{
		font-size: 1rem;
	}

}

.img-search-container {
	position: absolute;

	@media (max-width: $cw-screen-xs) {
		position: relative;
		height: 270px;
	}

	@media (max-width: $cw-screen-xxs) {
		height: 250px;
	}
}

.anchor-img-search{
	@include cw-width-calc(100%, "-", 30px);
	height: 100%;
	position: absolute;
	top: 0;
	left: 15px;
	right: 15px;
	bottom: 0;
	opacity: .8;
}

//* === Page-ricette === *//
.img-page-ricette{
	height: 350px;

	@media screen and (max-width: $cw-screen-xs) {
			height: 300px;
	}

	@media screen and (max-width: $cw-screen-xxs) {
			height: 250px;
	}
}

body.page-ricette {

  & .info-container{
	 margin-top: 1rem;
	 background-color: #eee;
 	 color: #333;
 	 @include cw-border-radius(10px, 10px, 10px, 10px);
	 padding-top: 1.2rem;
	 padding-bottom: 1.2rem;

	 & .title-sidebar {
		 font-family: $font-family-sans-serif;
		 text-transform: none;
		 font-size: 3rem;

		 @media (max-width: $cw-screen-md) {
			 font-size: 2.5rem;
		 }

		 @media (max-width: $cw-screen-sm) {
			 font-size: 3.25rem;
		 }

		 @media (max-width: $cw-screen-xxs) {
			 font-size: 2.85rem;
		 }

	 }
  }

	& .cw-post-header-elements-container{
		& > span{
			display: table;
			width: 47%;
			margin-bottom: 0.5rem;
			float: left;

			&:last-child{
				margin-bottom: 1rem;
			}

			& > i {
				font-size: 1.8rem;
				display: table-cell;
				vertical-align: middle;
				width: 2.5rem;

				@media (max-width: $cw-screen-md) {
					font-size: 2.4rem;
				}
			}

			& > span {
				display: table-cell;
				vertical-align: middle;
				font-size: 1.05rem;

				@media (max-width: $cw-screen-md) {
					padding-right: 5px;
					padding-left: 5px;
				}

				@media (max-width: $cw-screen-sm) {
					padding-right: 10px;
					padding-left: 10px;
					font-size: 1.2rem;
				}

				@media (max-width: $cw-screen-xs) {
					font-size: 1.4rem;
				}

			}

			@media (max-width: $cw-screen-md) {
				width: 100%;
				font-size: 1.4rem;
			}

			@media (max-width: $cw-screen-sm) {
				width: auto;
				font-size: 2rem;
				display: inline-block;
			}

			@media (max-width: $cw-screen-xxs) {
				display: block;
				width: 100%;
			}

		}
	}
}

ul.list-page-ricette{
	padding-left: 0;
	margin-bottom: 0;

	// @media (max-width: $cw-screen-md) {
	// 	margin-bottom: -20px;
	// }
	//
	// @media (max-width: $cw-screen-sm) {
	// 	margin-bottom: 0;
	// }

	& > li {
		font-size: 1.05rem;
		margin-bottom: 2px;

		& > .nome-ingrediente{
			font-weight: bold;
			color: $brand-primary;
		}

		& > .dose-ingrediente{
			display: block;

			@media (max-width: $cw-screen-md) {
				display: inline-block;
				margin-right: 0.5rem;
			}
		}

		@media (max-width: $cw-screen-md) {
			font-size: 1.05rem;
		}

		@media (max-width: $cw-screen-sm) {
			font-size: 1.2rem;
		}

		@media (max-width: $cw-screen-xs) {
			font-size: 1.4rem;
		}

	}

}

ul.list-contact{
	padding-left: 0;

	& > li{

		list-style: none;
		margin-bottom: 5px;
		font-weight: $font-weight-bold;

		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: 1.05rem;
			line-height: 1.3rem;
		}

		@media screen and (min-width: $cw-screen-xs*1+1) {
			font-size: 1.05rem;
			line-height: 1.3rem;
		}
	}

	& > li > a{
		&:hover, &:focus, &:active{
			color: darken($brand-primary, 8%);
		}
	}

}

.googlemaps{
	width: 100%;
	height: 300px;

	@media screen and (max-width: $cw-screen-sm) {
		height: 250px;
	}
}

.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
	width: 100% !important;
}

.aside-social{
	@media screen and (max-width: $cw-screen-xxs) {
		width:100%;
	}
}

.g-recaptcha{
	display: inline-block;

	@media screen and (max-width: $cw-screen-xxs) {
		transform: scale(0.8);
		transform-origin:0 0;
	}
}

// body.space-for-popup:after {
// 	@media screen and (max-width: $cw-screen-md) {
// 		content: " ";
// 		width: 100%;
// 		height: 64.5px;
// 		display: block;
// 	}
// }

#popup{
	opacity: 0;
	right: -300px;
	position: fixed;
	bottom: 20px;


	width: auto;
	padding: 15px;

	font-size: 1.7rem;
	font-weight: 600;

	color: #fff;
	z-index: 1020;
	background-color: $brand-primary;
	text-align: center;

	@include cw-border-radius(10px, 10px, 10px, 10px);

	@media screen and (max-width: $cw-screen-md) {
		width: 100%;
		bottom: -120px;
		left: 0;
		right: 0;
		@include cw-border-radius(0, 0, 0, 0);
		padding-top: 4px;
		padding-bottom: 4px;
		font-size: 1.5rem;
	}

	& #close-popup {
		position: absolute;
		top: 2px;
		right: -5px;
		font-size: 1.5rem;
	}

	& .vuoi-saperne{

	}

	& a{
		font-size: 2.2rem;
		color: #fff;
		display: inline-block;
		text-transform: uppercase;
		padding: 0 10px;

		@media screen and (max-width: $cw-screen-md) {
			font-size: 1.8rem;
		}
	}
}

.popup-reveal {
	right: 20px !important;

	@media screen and (max-width: $cw-screen-md) {
		right: 0 !important;
		bottom: 0 !important;
	}

}

// * {
//   -webkit-tap-highlight-color: rgba(0,0,0,0);
//   -webkit-tap-highlight-color: transparent; /* For some Androids */
// }

.cw-nav{
	position: fixed;
	width: 100%;
	height: 100px;
	top: 0;
	left: 0;
	z-index: 1050;

	transition: all .3s ease;

	@media screen and (max-width: $cw-screen-sm) {
		height: 84px;
		position: absolute;
	}

	@media screen and (max-width: $cw-screen-xxs) {
		height: 78px ;
	}
}

.top-animate {
    background: #fff !important;
    top: 13px !important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.mid-animate {
    opacity: 0;
}
.bottom-animate {
    background: #fff !important;
    top: 13px !important;
    -webkit-transform: rotate(-225deg);
    transform: rotate(-225deg);
}
.top-menu {
    top: 5px;
    width: 100%;
    height: 2px;
    @include cw-border-radius(10px, 10px, 10px, 10px);
    background-color: #F9A530;
}
.mid-menu {
    top: 13px;
		width: 100%;
    height: 2px;
    @include cw-border-radius(10px, 10px, 10px, 10px);
    background-color: #F9A530;
}
.bottom-menu {
    top: 21px;
		width: 100%;
    height: 2px;
		@include cw-border-radius(10px, 10px, 10px, 10px);
    background-color: #F9A530;
}
.menui {
    background: #fff;
    transition: 0.6s ease;
    transition-timing-function: cubic-bezier(.75, 0, .29, 1.01);
    margin-top: 0;
    position: absolute;
}
.icon {
    z-index: 999;
    position: fixed;
    background: none;
    display: block;
    padding: 0;
    height: 32px;
    width: 32px;
    margin: 0px;
    top: 35px;
    right: 15px;

		@media screen and (max-width: $cw-screen-lg) {
			top: 27px;
		}

		@media screen and (max-width: $cw-screen-md) {
			position: absolute;
			top: 28px;
		}

		@media screen and (max-width: $cw-screen-sm) {
			width: 27px;
			height: 27px;
		}

		@media screen and (max-width: $cw-screen-xxs) {
			top: 26px;
		}


}
.icon-mobile{
	position: fixed;
}

.mobilenav {
    font-family: inherit;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
		padding: 0;
		list-style: none;
    position: fixed;
    width: 100%;
    height: 100%;
		@include linear-gradient(to right, rgba(203,52,67,0.9), rgba(240,135,97,0.9));

		& li{
			list-style-type: none;
			text-align: center;
			padding: 0;
			display: table;
			width: 100%;
			height: 25%;

			@media screen and (max-height: 400px) {
				width: 25%;
				height: 100%;
				float: left;
			}

			@media screen and (max-height: 400px) and (max-width: $cw-screen-xxs) {
				width: 50%;
				height: 50%;
				float: left;
			}


			& > span{
				 display: table-cell;
				 vertical-align: middle;
				 width: 100%;
				 text-align: center;
			}

			& a{
				color: #fff;
				text-decoration: none;
				font-weight: 300;
				width: auto;
				text-transform: uppercase;
			}
		}
}


.opacity-block {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: 99;
	// rimetti per effetto animate
	opacity: 0;
	transition: all 1s ease;
	// opacity: .7;

	@media screen and (max-width: $cw-screen-xs) {
		display: none;
	}

}

// rimetti per effetto animate

.opacity-block-add-opacity{
	opacity: .65;
	transition: all 1s ease;
}

.line-gradient {
	@include linear-gradient(to right, #cb3443, #f08761);
	width: 10rem;
	height: 4px;
	display: inline-block;
}

.cw-icon {
	font-size: 6rem;

	@media screen and (max-width: $cw-screen-xl) {
		font-size: 5rem;
	}

	@media screen and (max-width: $cw-screen-sm) {
		font-size: 4rem;
	}

	@media screen and (max-height: 500px) {
		font-size: 3rem;
	}

}

.cw-icon-text {
	font-size: 1.75rem;
	line-height: 4rem;

	@media screen and (max-width: $cw-screen-sm) {
		font-size: 1.5rem;
		line-height: 3.5rem;
	}
}

h3.cw-subtitle-hero {
	line-height: 2rem;
	font-style: italic;
}

.section-scrollify{
	height: 100%;
}

.cw-margin-v-xxl{
	margin-top: 5rem;
	margin-bottom: 5rem;
}

.cw-subtitle-content {
    font-size: 1.2rem;
    line-height: 1.65rem;
    font-weight: 300;

		@media screen and (max-width: $cw-screen-xs) {
			font-size: 1.4rem;
	    line-height: 1.95rem;
		}
}

h1.cw-title-hero, h2.cw-title-hero {
	font-size: 4.3rem;
	line-height: 4.9rem;
	text-transform: uppercase;
	margin-bottom: 0;
	color: $brand-tertiary;
	font-weight: 200;

	@media screen and (max-width: $cw-screen-lg) {
		font-size: 3.5rem;
		line-height: 3.6rem;
	}

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 3rem;
		line-height: 3.2rem;
	}

	@media screen and (max-width: $cw-screen-xxs) {
		font-size: 2.3rem;
		line-height: 2.6rem;
	}

}

h3.cw-subtitle-hero{
	font-size: 2.25rem;
	line-height: 2.9rem;

	@media screen and (max-width: $cw-screen-lg) {
		font-size: 1.7rem;
		line-height: 2rem;
	}

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 1.5rem;
		line-height: 2rem;
	}

}

h2.cw-title-section {
	font-size: 3rem;
	line-height: 3.3rem;
	text-transform: uppercase;
	margin-bottom: 0;
	font-weight: $font-weight-light;

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 2.8rem;
		line-height: 3rem;
	}

	@media screen and (max-width: $cw-screen-xxs) {
		font-size: 2.5rem;
		line-height: 2.8rem;
	}
}

h3.cw-subtitle-section {
	font-size: 1.6rem;
	line-height: 2.2rem;
	font-style: italic;
	margin-top: 0;
	margin-bottom: 2.5rem;
	font-weight: 300;

	@media screen and (max-width: $cw-screen-xxs) {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

h4, .h4-link {
	font-size: 2.9rem;
	line-height: 3.2rem;
	font-weight: 200;
	text-transform: uppercase;
	margin-top: 1rem;
	margin-bottom: 1rem;

	@media screen and (max-width: $cw-screen-sm) {
		font-size: 2.1rem;
		line-height: 2.6rem;
		font-weight: 400;
	}

}

.h4-servizi {
	font-size: 1.9rem;
	line-height: 2.2rem;
	font-weight: 400;
}

.lead {
	font-size: 1.3rem;
	line-height: 1.8rem;
	font-weight: 300;
	margin-top: 1rem;
	margin-bottom: 1rem;

	@media screen and (max-width: $cw-screen-sm) {
		font-size: 1.4rem;
		line-height: 1.95rem;
	}
}

.line-01{
	background-color: #fff;
	width: 15rem;
}

.line-02{
	background-color: #1d1d1d;
	width: 15rem;
}

.servizi-img{
	width: 60%;
	margin-top: 2rem;

	@media screen and (max-width: $cw-screen-xs) {
		width: 50%;
	}
}

.servizi-icon-outer{
	height: 400px;
	margin-bottom: 35px;
	display:inline-block;
	float: none;
	text-align: center;
	vertical-align: top;

	@media screen and (max-width: $cw-screen-lg) {
		height: 380px;
	}

	// @media screen and (max-width: $cw-screen-md) {
	// 	height: 380px;
	// }

	@media screen and (max-width: $cw-screen-sm) {
		height: 370px;
		margin-bottom: 25px;
	}

	@media screen and (max-width: $cw-screen-xs) {
		height: auto;
		margin-bottom: 20px;
	}
}

.opacity-0 {
	opacity: 0;
}

.form-control {
		// border-color: #333;
		background-color: transparent;
		border-width: 1px;
		border-color: #aaa;
		color: #eee;

		@include cw-border-radius(0, 0, 0, 0);

		&::-webkit-input-placeholder { color: #8a8a8a; }
		&:-moz-placeholder { color: #8a8a8a; }
		&::-moz-placeholder { color: #8a8a8a; }
		&:-ms-input-placeholder { color: #8a8a8a; }

		// #contactFormContatti &{
		// 	border-width: 1px;
		// 	border-color: #aaa;
		// 	color: #eee;
		// 	@include cw-border-radius(0, 0, 0, 0);
		//
		// 	&::-webkit-input-placeholder { color: #8a8a8a; }
		// 	&:-moz-placeholder { color: #8a8a8a; }
		// 	&::-moz-placeholder { color: #8a8a8a; }
		// 	&:-ms-input-placeholder { color: #8a8a8a; }
		// }
}

#owl-header{
	& .owl-controls{
		display: none;
	}
}

#owl-ultimi-progetti{
	height: 650px;


  @media screen and (max-width: $cw-screen-xl) {
    height: 500px;
  }

  @media screen and (max-width: $cw-screen-lg) {
    height: 420px;
  }

	@media screen and (max-width: $cw-screen-md) {
		height: auto;
	}

}

.border-bottom-1px{
	border-bottom: 1px solid #fff;
	display: inline-block;
	padding-bottom: 1.25rem;
	margin-bottom: 0;

	@media screen and (max-width: $cw-screen-sm) {
		text-align: center;
	}
}

 // *=== PACE JS ===* //

 .pace {
   -webkit-pointer-events: none;
   pointer-events: none;

   -webkit-user-select: none;
   -moz-user-select: none;
   user-select: none;
 }

 .pace-inactive {
   display: none;
 }

 .pace .pace-progress {
  // background: #fff;

	@include linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 95%, rgba(255,255,255,0) 100%);

   position: fixed;
   z-index: 9999;
   top: 49.9%;
   right: 100%;
   width: 100%;
   height: 3px;
 }

 div.paceDiv {
    position: fixed;
		z-index: 9998;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
    width: 100%;
    height: 100%;

		@include linear-gradient(to right, #cb3443, #f08761);

}

.nav-height-100{
	height: 100%;
}

.navbar-scroll.nav-bg-color{
	background-color: #333;
	transition: all .3s ease;
}

.cw-form-contact {

	& span{
		font-size: 2.05rem;
		text-transform: uppercase;
		font-weight: 600;
	}
	//
	// & .fa-envelope {
	// 	font-size: 2.2rem;
	// }

	& .btn{
		padding: 5px 12px;
	}

}

.form-control {
	font-size: 1.3rem;
	color: #fff;
}

.cw-icon-contatti{
	font-size: 2.5rem;
	top: 4px;
	position: relative;
}

.element-to-animate {
	opacity: 0;
	-webkit-backface-visibility: hidden;
}

.blocco-nero{
	background-color: #000;
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1000;
	display: none;
}

.blocco-nero.animation-duration-0 {
	-webkit-animation-duration:0.5s;
	-moz-animation-duration:0.5s;
	animation-duration:0.5s;

	-webkit-animation-delay:0s;
	-moz-animation-delay:0s;
	animation-delay:0s;
}

.font-size-0 {
	font-size: 0;
}

// *=== Servizi ===* //

#pagina-servizio {
	color: #1d1d1d;
}

.bg-semi-white {
	background-color: #ededed;
}

.bg-semi-dark {
	background-color: #1D1D1D;
}

.bg-brand-primary {
	background-color: $brand-primary;
}

.bg-gradient {
	@include linear-gradient(to right, #cb3443, #f08761);
}

.bg-yellow-flat {
	background-color: #ffdb66;
}

.bg-parallax-servizio {
	width:100%;
	height:100%;

	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;

	@media screen and (max-width: $cw-screen-xs) {
		height: 250px;
		background-attachment: scroll;
		background-position: center center !important;
	}
}

.bg-parallax-contatti {
	@extend .bg-parallax-servizio;
	position: absolute;
	background-image:url('/lib/images/bg-contatti.jpg');

	@media screen and (max-width: $cw-screen-xs) {
		position: relative;
		background-image:url('/lib/images/bg-contatti-resize.jpg');
	}
}



.bg-parallax-end-page-form {
	@extend .bg-parallax-servizio;
	height: auto;

	@media screen and (max-width: $cw-screen-xs) {
		height: auto;
	}
}



.bg-parallax-repeated {
	@extend .bg-parallax-servizio;

	position: absolute;
	height: 100%;
	background-repeat: repeat;
	background-image:url('/lib/images/bg-repeat.png');
	z-index: 0;

	// @media screen and (max-width: $cw-screen-xs) {
	//
	// }
}



.cw-bg-web-design {
	width:100%;
	height:100%;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	@media screen and (max-width: $cw-screen-xs) {
		height: 250px;
	}
}

.bg-servizio-1 {
	@include linear-gradient(to top, rgba(203,52,67,1), rgba(240,135,97,1));
}

.bg-opacity-black {
	@include background-opaco(#000000,.6);
}

.color-semi-black{
	color: #1d1d1d;
}

.color-white{
	color: #fff;
}

.btn-contatti-pages, .btn-link-portfolio-scheda {
	text-transform: uppercase;
	font-size: 1.8rem;
	line-height: 2.3rem;
	font-weight: 600;
	margin-top: 1.5rem;

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 1.5rem;
		line-height: 1.95rem;
		padding: 10px;
	}

	@media screen and (max-width: $cw-screen-xxs) {
		font-size: 1.3rem;
		line-height: 1.6rem;
		padding: 8px;
	}
}

.btn-link-portfolio-scheda {
	font-size: 1.5rem;
	line-height: 1.8rem;
	padding: 10px 12px;
	margin-top: 0;
	margin-bottom: 1rem;
	float: left;
	clear: both;

	&:last-child {
		margin-bottom: 2rem;
	}
}

.btn-vuoi-anche-tu {
	@media screen and (max-width: $cw-screen-xs) {
		font-weight: 600;
		font-size: 1.7rem;
		line-height: 2rem;
	}
}

.cw-bg-center{
	background-position: center center;;
}

.box-vuoi-preventivo {
	width:100%;
	height:450px;

	@media screen and (max-width: $cw-screen-xs) {
		height: 350px;
		background-attachment: scroll;
		background-position: center center !important;
	}
}

.left-minus-35-xs {
	left: -35%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-plus-35-xs {
	left: 35%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-minus-40-xs {
	left: -40%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-plus-40-xs {
	left: 40%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-minus-50-xs {
	left: -50%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-plus-50-xs {
	left: 50%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-minus-60-xs {
	left: -60%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-plus-60-xs {
	left: 60%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-minus-65-xs {
	left: -65%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

.left-plus-65-xs {
	left: 65%;

	@media screen and (max-width: $cw-screen-xs) {
		left: auto;
	}
}

// *=== FLIP CSS EFFECT ===* //
/* entire container, keeps perspective */
.flip-container {
	color: #1d1d1d  !important;
	-webkit-perspective: 1000px;
		 -moz-perspective: 1000px;
					perspective: 1000px;

	@media screen and (max-width: $cw-screen-xs) {
		-webkit-perspective: none;
			 -moz-perspective: none;
						perspective: none;
	}

	/* flip the pane when hovered */
	&:hover .flipper, &.hover .flipper {
		@include cw-transform-rotateY(180deg);
		will-change: transform;

		@media screen and (max-width: $cw-screen-xs) {
			-webkit-transform: none !important;
				 -moz-transform: none !important;
					-ms-transform: none !important;
					 -o-transform: none !important;
							transform: none !important;
		}
	}

}

/* flip speed goes here */
.flipper {

	-webkit-transition: 0.6s;
	   -moz-transition: 0.6s;
	     -o-transition: 0.6s;
	        transition: 0.6s;

	-webkit-transform-style: preserve-3d;
		 -moz-transform-style: preserve-3d;
				  transform-style: preserve-3d;

	position: relative;
	display: block;
	width: 100%;
	height: 100%;
}

/* hide back of pane during swap */
.front, .back {
	-webkit-backface-visibility: hidden;
		 -moz-backface-visibility: hidden;
	        backface-visibility: hidden;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@media screen and (max-width: $cw-screen-xs) {
		-webkit-transform: none !important;
			 -moz-transform: none !important;
				-ms-transform: none !important;
				 -o-transform: none !important;
						transform: none !important;
	}
}

/* front pane, placed above back */
.front {
	position: relative;
	z-index: 2;
	@include cw-transform-rotateY(0deg);
	background-color: #EDEDED;
}

/* back, initially hidden pane */
.back {
	@include cw-transform-rotateY(180deg);

	@media screen and (max-width: $cw-screen-xs) {
		display: none;

		-webkit-transform: none !important;
			 -moz-transform: none !important;
				-ms-transform: none !important;
				 -o-transform: none !important;
						transform: none !important;
	}

	& .back-p {
		font-size:1.6rem;
		line-height:2rem;
		text-transform:uppercase;
	}

	& .servizi-img {
		width: 60%;
		margin-top: 0;

		@media screen and (max-width: $cw-screen-xs) {
			display: none;
		}
	}

}

ul.elenco-servizi {
	margin-bottom: 20px;


	@media screen and (min-width: $cw-screen-xs*1+1) {
		font-size: 14px;
		line-height: 18px;

		> li {
			font-size: 1.18rem;
			line-height: 1.6rem;
			margin-bottom: 5px;
		}
	}

}

// *=== Contatti ===* //

h2.cw-title-hero-contatti {
	font-size: 2.4rem;
	line-height: 2.85rem;
	margin-bottom: 3.4rem;
	text-transform: none;
	font-style: italic;

	@media screen and (max-width: $cw-screen-md) {
		font-size: 2.2rem;
		margin-bottom: 2.5rem;
	}
}

ul.cw-list-contatti {
	list-style: none;
	margin: 0 0 3.6rem;;
	padding: 0;
	font-size: 1.4rem;

	@media screen and (max-width: $cw-screen-xs) {
		font-size: 1.5rem;
	}

	> li {
		display: inline-block;
		margin-right: 1.7rem;
		margin-bottom: 0.5rem;

		@media screen and (max-width: $cw-screen-xs) {
			display: block;
			margin-bottom: 1rem;
		}

		& > a {
			color: #fff;

			& span {
				border-bottom: 1px  solid transparent;
				transition: $cw-transition;
				padding-bottom: 0.3rem;
			}

			&:hover, &:active, &:focus {
				& span {
					border-bottom: 1px  solid $brand-primary;
					transition: $cw-transition;
				}
			}

			& > i {
				color: $brand-primary;
				margin-right: 0.5rem;
			}

		}

		&:last:child {
			margin-right: 0;
		}
	}


}

#contatti-home, #contatti-web-design {
	padding-top: 5rem;
	padding-bottom: 5rem;

	& .opacity-block-add-opacity{
		@media screen and (max-width: $cw-screen-xs) {
			display: block;
		}
	}

}

.container-home-contatti {
	position: relative;
	z-index: 101;
}

.desc-web-design-dx {
	width: 32%;
	right: 4%;
	height: 100%;
	float: none;
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	color: #fff !important;
	position: relative;
	padding-left: 15px;
	padding-right: 15px;

	@media screen and (max-width: $cw-screen-lg) {
		width: 45%;
		right: auto;
	}

	@media screen and (max-width: $cw-screen-md) {
		width: 100%;
		display: block;
		padding: 30px;
		text-align: center;
	}
}

.img-web-design-sx {
	width: 60%;
	height: 100%;
	float: none;
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	color: #fff;
	position: relative;
	padding-left: 5px;
	padding-right: 5px;

	@media screen and (max-width: $cw-screen-lg) {
		width: 55%;
	}

	@media screen and (max-width: $cw-screen-md) {
		width: 100%;
		display: inline-block !important;
		text-align: center;
	}
}

.desc-web-design-sx {
	width: 32%;
	left: -50%;
	height: 100%;
	float: none;
	display: table-cell;
	vertical-align: middle;
	text-align: right;
	color: #222;
	position: relative;
	padding-left: 15px;
	padding-right: 15px;

	@media screen and (max-width: $cw-screen-lg) {
		width: 45%;
		left: -55%;
	}

	@media screen and (max-width: $cw-screen-md) {
		width: 100%;
		left: auto;
		display: block;
		padding: 30px;
		text-align: center;
	}
}

.img-web-design-dx {
	width: 55%;
	left: 38%;
	height: 100%;
	float: none;
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	color: #fff;
	position: relative;
	padding-left: 5px;
	padding-right: 5px;

	@media screen and (max-width: $cw-screen-lg) {
		width: 55%;
		left: 45%;
	}

	@media screen and (max-width: $cw-screen-md) {
		width: 100%;
		left: auto;
		display: inline-block !important;
		text-align: center;
	}

}

#web-design-riga-01 {
	z-index: 99;
	position: relative;

	img {
		// margin-top: -250px;
		@media screen and (max-width: $cw-screen-md) {
			margin-top: 0;
		}
	}

	.border-bottom-1px {
		border-color: #fff;
		text-align: left;

		@media screen and (max-width: $cw-screen-sm) {
			text-align: center;
		}
	}

	.cw-scopri-web-design {
		border-color: #fff;
		color: #fff;
		text-align: center;

		&:hover, &:focus, &:active {
			border-color: #fff;
			color: #1d1d1d;
		}
	}

	p.btn-container {
		@media screen and (max-width: $cw-screen-xs) {
			text-align: center;
		}
	}
}

#web-design-riga-02 {

	.desc-web-design-sx {

		.h4-link, p {
			color: #666;

			@media screen and (max-width: $cw-screen-md) {
				text-align: center;
			}

		}

		p.btn-container {
			@media screen and (max-width: $cw-screen-xs) {
				text-align: center;
			}
		}

		.border-bottom-1px {
			border-color: #666;
			text-align: right;

			@media screen and (max-width: $cw-screen-md) {
				text-align: center;
			}
		}

		.cw-scopri-web-design {
			border-color: #666;
			color: #666;

			&:hover, &:focus, &:active {
				border-color: #fff;
				color: #fff;
				background-color: #1d1d1d;
			}
		}

	}



}

#web-design-riga-03 {
	margin-bottom: 0;

	.desc-web-design-dx {

		.h4-link, p {
			color: #1d1d1d;

			@media screen and (max-width: $cw-screen-md) {
				text-align: center;
			}
		}

		p.btn-container {
			@media screen and (max-width: $cw-screen-xs) {
				text-align: center;
			}
		}

		.border-bottom-1px {
			border-color: #1d1d1d;
			text-align: left;

			@media screen and (max-width: $cw-screen-md) {
				text-align: center;
			}
		}

		.cw-scopri-web-design {
			border-color: #1d1d1d;
			color: #1d1d1d;

			&:hover, &:focus, &:active {
				border-color: #fff;
				color: #1d1d1d;
			}
		}
	}

}

.h4-link-web-design {
	font-size: 2.3rem;
	line-height: 2.8rem;
	font-weight: 300;
}

.cw-subtitle-content-web-design {
	font-size: 1.25rem;
    line-height: 1.7rem;

	@media screen and (max-width: $cw-screen-md) {
		font-size: 1.65rem;
		line-height: 2.1rem;
	}

	// @media screen and (min-width: $cw-screen-xs*1+1) {
  //   font-size: 1.25rem;
  //   line-height: 1.7rem;
	// }

}



.grid-item {
	overflow: hidden;

	&:before{
    content: "";
    display: block;
    padding-top: 100%;
	}

	.scheda-portfolio-container {
		position: absolute;
		top:15px;
		left:15px;
		right:15px;
		bottom:15px;
		text-align: center;
		overflow: hidden;


		&:hover, &.hover {
			.scheda-portfolio-container-hidden {
					opacity: 1;
					// transition: $cw-transition;

					-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
						 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
							-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
									transition: opacity 0.55s,         transform 0.55s;
			}

			.scheda-portfolio-container-hidden-inner  {
				-webkit-transform: scale(1);
					 -moz-transform: scale(1);
						-ms-transform: scale(1);
								transform: scale(1);

				-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
					 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
						-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
								transition: opacity 0.55s,         transform 0.55s;

			}
		}

		.scheda-portfolio-container-hidden::before,
		.scheda-portfolio-container-hidden::after {
			position: absolute;
			top: 30px;
			right: 30px;
			bottom: 30px;
			left: 30px;
			content: '';
			opacity: 0;

			-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
				 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
					-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
							transition: opacity 0.55s,         transform 0.55s;

			z-index: 0;
		}

		.scheda-portfolio-container-hidden::before {
			border-top: 1px solid #fff;
			border-bottom: 1px solid #fff;

			-webkit-transform: scale(0,1);
				 -moz-transform: scale(0,1);
				  -ms-transform: scale(0,1);
						  transform: scale(0,1);
		}

		.scheda-portfolio-container-hidden::after {
			border-right: 1px solid #fff;
			border-left: 1px solid #fff;

			-webkit-transform: scale(1,0);
				 -moz-transform: scale(1,0);
					-ms-transform: scale(1,0);
							transform: scale(1,0);
		}

		.post-image-container, img, article, .scheda-portfolio-container-hidden-inner  {
			width: 100%;
			height: 100%;
		}

		.scheda-portfolio-container-hidden-inner  {
			position: relative;
			z-index: 1;

			-webkit-transform: scale(0.25);
				 -moz-transform: scale(0.25);
					-ms-transform: scale(0.25);
							transform: scale(0.25);

			-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
			   -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
			    -ms-transition: opacity 0.55s,     -ms-transform 0.55s;
			        transition: opacity 0.55s,         transform 0.55s;
		}



		.nome-scheda-portfolio {
			color: #fff;

			font-size: 2.2rem;
			line-height: 2.4rem;
			font-weight: 500;
			text-transform: uppercase;
			padding-bottom: 4.5rem;

			@media screen and (max-width: $cw-screen-lg) {
				font-size: 1.9rem;
				line-height: 2.15rem;
				padding-bottom: 3.5rem;
			}

			@media screen and (max-width: $cw-screen-md) {
				font-size: 1.8rem;
				line-height: 2rem;
				padding-bottom: 3.2rem;
			}
			@media screen and (max-width: $cw-screen-sm) {
				font-size: 2.2rem;
				line-height: 2.4rem;
				text-transform: uppercase;
				padding-bottom: 4.5rem;
			}

			@media screen and (max-width: $cw-screen-xs) {
				font-size: 1.7rem;
				line-height: 1.95rem;
				padding-bottom: 2rem;
			}
		}

		.nome-scheda-portfolio-2 {
			font-size: 1.8rem;
			line-height: 2.2rem;

			@media screen and (max-width: $cw-screen-lg) {
				font-size: 1.9rem;
				line-height: 2.15rem;
				padding-bottom: 3.5rem;
			}

			@media screen and (max-width: $cw-screen-md) {
				font-size: 1.8rem;
				line-height: 2rem;
				padding-bottom: 3.2rem;
			}
			@media screen and (max-width: $cw-screen-sm) {
				font-size: 2.2rem;
				line-height: 2.4rem;
				text-transform: uppercase;
				padding-bottom: 4.5rem;
			}

			@media screen and (max-width: $cw-screen-xs) {
				font-size: 1.7rem;
				line-height: 1.95rem;
				padding-bottom: 2rem;
			}
		}

		.desc-scheda-portfolio {
			color: #fff;

			font-size: 1.5rem;
			line-height: 2rem;
			font-weight: 300;
			padding-bottom: 4.5rem;

			@media screen and (max-width: $cw-screen-lg) {
				font-size: 1.4rem;
				line-height: 1.95rem;
				padding-bottom: 3.5rem;
			}

			@media screen and (max-width: $cw-screen-md) {
				font-size: 1.35rem;
				line-height: 1.8rem;
				padding-bottom: 3.2rem;
			}

			@media screen and (max-width: $cw-screen-sm) {
				font-size: 1.5rem;
				line-height: 2rem;
				font-weight: 200;
				padding-bottom: 4.5rem;
			}

			@media screen and (max-width: $cw-screen-xs) {
				display: none;
			}
		}

		.link-scheda-portfolio {
			font-size: 1.5rem;
			line-height: 1.8rem;
			padding: 7px 15px;
			color: #1d1d1d;
			background-color: #fff;
			text-transform: uppercase;
			display: inline-block;

			// &:hover, &:focus, &:active, &.active {
			// 	background-color: #1d1d1d;
			// 	color: #fff;
			// 	transition: $cw-transition;
			// }

			@media screen and (max-width: $cw-screen-lg) {
				font-size: 1.4rem;
				line-height: 1.95rem;
			}

			@media screen and (max-width: $cw-screen-md) {
				font-size: 1.35rem;
				line-height: 1.8rem;
			}

			@media screen and (max-width: $cw-screen-sm) {
				font-size: 1.5rem;
				line-height: 1.8rem;
			}

			@media screen and (max-width: $cw-screen-xs) {
				font-size: 1.3rem;
				line-height: 1.7rem;
			}
		}

	}

	.scheda-portfolio-container:hover .scheda-portfolio-container-hidden::before,
	.scheda-portfolio-container.hover .scheda-portfolio-container-hidden::before,
	// .scheda-portfolio-container:focus .scheda-portfolio-container-hidden::before,
	.scheda-portfolio-container:hover .scheda-portfolio-container-hidden::after,
	.scheda-portfolio-container.hover .scheda-portfolio-container-hidden::after,
	// .scheda-portfolio-container:focus .scheda-portfolio-container-hidden::after
	{
		opacity: 1;
		-webkit-transform: scale(1);
			 -moz-transform: scale(1);
				-ms-transform: scale(1);
						transform: scale(1);
	}


}

.scheda-portfolio-container-hidden {
	@include linear-gradient(45deg, rgba(203,52,67,0.8), rgba(240,135,97,0.95));

	opacity: 0;

	-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
		 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
			-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
					transition: opacity 0.55s,         transform 0.55s;

	color: #1d1d1d;
	// transition: $cw-transition;
	padding: 3rem;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: table;
	width: 100%;
	height: 100%;

	& .scheda-portfolio-container-hidden-inner {
		padding: 3rem;
		// border: 1px solid #fff;
		display: table-cell;
		vertical-align: middle;

		@media screen and (max-width: $cw-screen-lg) {
			padding: 2rem;
		}

		@media screen and (max-width: $cw-screen-md) {
			padding: 1.5rem;
		}

		@media screen and (max-width: $cw-screen-sm) {
			padding: 3rem;
		}

		@media screen and (max-width: $cw-screen-xs) {
			padding: 1.2rem;
		}
	}

}

.scheda-portfolio-container-hidden .scheda-portfolio-container-hidden-inner-2 {
	padding: 1.5rem;
}

.list-isotope {
	padding: 2rem 0;
	margin: 0;
	text-align: center;

	> li {
		display: inline-block;
		margin-right: 2rem;

		> a {
			font-size: 1.45rem;
			font-weight: 400;
			line-height: 1.8rem;
			text-transform: uppercase;

			border-bottom: 3px solid transparent;

			padding: 25px 10px;
			color:#ddd;

			&:hover, &:focus, &:active, &.is-checked {
				color: #eee;
				border-bottom: 3px solid #eee;

				@media screen and (max-width: $cw-screen-md) {
					border-bottom: none;
					background-color: transparent;
					border: 1px solid #fff;
					color: #fff;
					padding: 5px 10px;
				}
			}
/*
			@media screen and (max-width: $cw-screen-sm) {
				font-size: 1.25rem;

				padding-bottom: 10px ;
			}
*/
			@media screen and (max-width: $cw-screen-md) {
				font-size: 1.35rem;
				border: 1px solid transparent;
				display: block;
				float: left;
				padding: 5px 10px;
				margin-bottom: 1rem;
			}

		}

		:last-child {
			margin-right: 0;
		}


		@media screen and (max-width: $cw-screen-lg) {
			margin-right: 1.5rem;
		}

	}

	@media screen and (max-width: $cw-screen-md) {
		text-align: left;
		padding-bottom: 0.5rem;
	}
}

#servizi-portfolio{
	h2, h3 {
		color: #1d1d1d;
	}
}

.list-isotope-container {
	position: relative;
	border-bottom: 1px solid #444;
	// margin-bottom: 2rem; // cwh ripristina quando rimetti isotope
	margin-bottom: 1rem;

}

.cw-padding-top-0 {
	padding-top: 0;
}

// *=== PORTFOLIO SCHEDA ===* //

ul.list-tag {
	float: left;
	clear: both;
	padding: 0;
	margin: 0;
	margin-top: 3px;

	li {
		display:inline-block;
		float: left;
		margin-right: 7px;
		margin-bottom: 7px;

		a {
			padding: 8px 16px;
			border: 1px solid #fff;
			display:inline-block;
			font-size: 1.15rem;
			font-weight: 200;

			color: #fff;

			&:hover, &:focus, &:active, &.active {
				color: #1d1d1d;
				background-color: #fff;
				transition: $cw-transition;
			}

			@media screen and (max-width: $cw-screen-xs) {
				font-size: 1.4rem;
			}
		}
	}
}

h2.cw-title-portfolio-scheda {
	float: left;
	padding: 1.5rem 0;
	font-size: 4.1rem;
	line-height: 4.0rem;
	font-weight: 200;
}

.arrow-portfolio {
	width: 50px;
	height: 50px;
	float: left;
	margin-right: 10px;
	margin-top: 0;
	margin-bottom: 30px;

	-webkit-transform: scale(1);
		 -moz-transform: scale(1);
			-ms-transform: scale(1);
					transform: scale(1);

	-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
		 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
			-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
					transition: opacity 0.55s,         transform 0.55s;

	&:hover {
		-webkit-transform: scale(1.1);
			 -moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
						transform: scale(1.1);

		-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
			 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
				-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
						transition: opacity 0.55s,         transform 0.55s;
	}
}

.img-gallery-scheda-portfolio-container {

	position:relative;

	width: 100%;
	margin-bottom: 2rem;
	display: inline-block;
	overflow: hidden;


	img {
		-webkit-transform: scale(1);
			 -moz-transform: scale(1);
				-ms-transform: scale(1);
						transform: scale(1);

		-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
			 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
				-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
						transition: opacity 0.55s,         transform 0.55s;
	}

	&:hover img {

		-webkit-transform: scale(1.25);
			 -moz-transform: scale(1.25);
				-ms-transform: scale(1.25);
						transform: scale(1.25);

		-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
			 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
				-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
						transition: opacity 0.55s,         transform 0.55s;
	}


	&:hover, &:focus, &:active {
		.img-gallery-scheda-portfolio-container-hidden {
				opacity: 1;

				-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
					 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
						-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
								transition: opacity 0.55s,         transform 0.55s;
		}

		.img-gallery-scheda-portfolio-container-hidden-inner  {
			-webkit-transform: scale(1);
				 -moz-transform: scale(1);
					-ms-transform: scale(1);
							transform: scale(1);

			-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
				 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
					-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
							transition: opacity 0.55s,         transform 0.55s;

		}
	}

	.img-gallery-scheda-portfolio-container-hidden::before,
	.img-gallery-scheda-portfolio-container-hidden::after {
		position: absolute;
		top: 30px;
		right: 30px;
		bottom: 30px;
		left: 30px;
		content: '';
		opacity: 0;

		-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
			 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
				-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
						transition: opacity 0.55s,         transform 0.55s;

		z-index: 0;
	}

	.img-gallery-scheda-portfolio-container-hidden::before {
		border-top: 1px solid #fff;
		border-bottom: 1px solid #fff;

		-webkit-transform: scale(0,1);
			 -moz-transform: scale(0,1);
			  -ms-transform: scale(0,1);
					  transform: scale(0,1);
	}

	.img-gallery-scheda-portfolio-container-hidden::after {
		border-right: 1px solid #fff;
		border-left: 1px solid #fff;

		-webkit-transform: scale(1,0);
			 -moz-transform: scale(1,0);
				-ms-transform: scale(1,0);
						transform: scale(1,0);
	}



	.img-gallery-scheda-portfolio-container-hidden-inner  {
		position: relative;
		z-index: 1;

		-webkit-transform: scale(0.25);
			 -moz-transform: scale(0.25);
				-ms-transform: scale(0.25);
						transform: scale(0.25);

		-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
		   -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
		    -ms-transition: opacity 0.55s,     -ms-transform 0.55s;
		        transition: opacity 0.55s,         transform 0.55s;
	}


}

.img-gallery-scheda-portfolio-container:hover .img-gallery-scheda-portfolio-container-hidden::before,
.img-gallery-scheda-portfolio-container.hover .img-gallery-scheda-portfolio-container-hidden::before,
.img-gallery-scheda-portfolio-container:focus .img-gallery-scheda-portfolio-container-hidden::before,
.img-gallery-scheda-portfolio-container:hover .img-gallery-scheda-portfolio-container-hidden::after,
.img-gallery-scheda-portfolio-container.hover .img-gallery-scheda-portfolio-container-hidden::after,
.img-gallery-scheda-portfolio-container:focus .img-gallery-scheda-portfolio-container-hidden::after {
	opacity: 1;
	-webkit-transform: scale(1);
		 -moz-transform: scale(1);
			-ms-transform: scale(1);
					transform: scale(1);
}


.img-gallery-scheda-portfolio-container-hidden {
	@include linear-gradient(45deg, rgba(203,52,67,0.8), rgba(240,135,97,0.95));

	opacity: 0;

	-webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
		 -moz-transition: opacity 0.55s,    -moz-transform 0.55s;
			-ms-transition: opacity 0.55s,     -ms-transform 0.55s;
					transition: opacity 0.55s,         transform 0.55s;

	color: #1d1d1d;
	// transition: $cw-transition;
	padding: 3rem;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: inline-block;
	width: 100%;
	height: 100%;

	& .img-gallery-scheda-portfolio-container-hidden-inner {
		padding: 3rem;
		// border: 1px solid #fff;
		display: table;
		vertical-align: middle;
		width: 100%;
		height: 100%;

		& .img-gallery-scheda-portfolio-container-hidden-inner-zoom {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			width:100%;
			height: 100%;

			& .fa-white {
				color: #fff;
					& .fa-black {
						color: #333;
					}
			}
		}

		@media screen and (max-width: $cw-screen-lg) {
			padding: 2rem;
		}

		@media screen and (max-width: $cw-screen-md) {
			padding: 1.5rem;
		}

		@media screen and (max-width: $cw-screen-sm) {
			padding: 3rem;
		}

		@media screen and (max-width: $cw-screen-xs) {
			padding: 1.2rem;
		}
	}

}

.list-portfolio-2 {
	margin-top: -5px;
}

.nome-cliente-scheda-portfolio, .desc-cliente-scheda-portfolio, .data-cliente-scheda-portfolio, .link-cliente-scheda-portfolio, .social-cliente-scheda-portfolio {
	font-size: 1.4rem;
	line-height: 1.95rem;

	margin-bottom: 30px;
	float: left;
	clear: both;

	.titolo {
		float: left;
		clear: both;
		display: inline-block;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-size: 1.9rem;
		font-weight: 700;
	}

	p {
		float: left;
		clear: both;
	}

	@media screen and (min-width: $cw-screen-xs*1+1) {
		font-size: 1.25rem;
		line-height: 1.65rem;
		font-weight: 200;
	}
}


h1.cw-title-hero-portfolio-scheda, h3.cw-subtitle-hero-portfolio-scheda  {
	text-align: left;
}

.demo-gallery figure {
    display: none;
}

.pswp__ui .pswp__caption__center {
	text-align: center;
}

#segnaposto .cw-title-hero {
	text-align: center;
	font-size: 3.25rem;
	line-height: 3.75rem;
}


//* === Scroll down button === *//

#scroll-down,
#scroll-down:before {
	position: absolute;
	left: 50%;

	@media screen and (max-width: $cw-screen-sm) {
		display: none;
	}
}

#scroll-down {
	width: 40px;
	height: 65px;
	margin-left: -20px;
	bottom: 30px;
	border: 3px solid #fff;

	@include cw-border-radius(25px, 25px, 25px, 25px);
	z-index: 999;

	@media screen and (max-width: $cw-screen-md) {
		width: 30px;
		height: 50px;
		margin-left: -15px;
		bottom: 30px;
		border: 2px solid #fff;
	}
}

#scroll-down:before {
	content: '';
	width: 8px;
	height: 8px;
	background: #fff;
	margin-left: -4px;
	top: 8px;
	@include cw-border-radius(4px, 4px, 4px, 4px);

	-webkit-animation-duration: 1.5s;
	   -moz-animation-duration: 1.5s;
	        animation-duration: 1.5s;

	-webkit-animation-iteration-count: infinite;
	   -moz-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;

	-webkit-animation-name: scroll;
	   -moz-animation-name: scroll;
	        animation-name: scroll;

	@media screen and (max-width: $cw-screen-md) {
		width: 6px;
		height: 6px;
		background: #fff;
		margin-left: -3px;
		top: 6px;
	}
}

@-webkit-keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		@include cw-transform-translateY(40px);
	}
}

@-moz-keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		@include cw-transform-translateY(40px);
	}
}

@-o-keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		@include cw-transform-translateY(40px);
	}
}

@keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		@include cw-transform-translateY(40px);
	}
}

@media screen and (max-width: $cw-screen-md) {

	@-webkit-keyframes scroll {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			@include cw-transform-translateY(30px);
		}
	}

	@-moz-keyframes scroll {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			@include cw-transform-translateY(30px);
		}
	}

	@-o-keyframes scroll {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			@include cw-transform-translateY(30px);
		}
	}

	@keyframes scroll {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			@include cw-transform-translateY(30px);
		}
	}

}
